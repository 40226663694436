/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { WEB_URL, FEEDBACK_STATUS, WEB_URLDEMO } from '../../../_metronic/helpers/components/constant'

import ReactTags from 'react-tag-autocomplete'

import Discount from './components/discount.png';
import Phone from './components/phone.png';
import Meeting from './components/meeting.png';
import Info from './components/information.png';
import Email from './components/emailto.png';
import EmailNotSent from './components/emailblock.png';
// import RatingImg from './components/rating.png';
import FeedbackImg from './components/feedback.png';
import { getLists, postMail, updateLeads, getFeedback } from './redux/DashboardCrud';
import { useDispatch, useSelector } from 'react-redux';
import { useLang } from '../../../_metronic/i18n/Metronici18n'
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ListWrapper() {
	const intl = useIntl();
	const [leads, setLeads] = React.useState([]);
	const [modalInfo, setModalInfo] = React.useState([]);
	const [customerData, setCustomerData] = React.useState({});
	const [customerReports, setCustomerReports] = React.useState({});
	const { data } = useSelector(({ auth }) => auth.user);
	const [emailModal, setEmailModal] = React.useState(false);
	const [feedbackModal, setFeedbackModal] = React.useState(false);
	const [feedbackModalMail, setFeedbackModalMail] = React.useState(false);
	const [ratingModal, setRatingModal] = React.useState(false);
	const [selectedItem, setSelectedItem] = React.useState(null);
	const [leadStatus, setLeadStatus] = useState();
	const [visible, setVisible] = React.useState(false);
	const [leadComment, setLeadComment] = useState();
	const [tags, setTags] = React.useState([]);
	const [temptags, setTempTags] = React.useState([]);
	const [currentUser, setCurrentUser] = useState({})
	const [leadchat, setLeadChat] = useState([]);
	const [mailstatus, setMailStatus] = React.useState(null);
	const [leadChatComments, setLeadChatComments] = useState('');

	const reactTags = useRef();
	const [suggestions, setSuggestion] = React.useState([]);
	const [salesReps, setSalesReps] = useState([])
	const [statusimages] = useState({
		OFFER: Discount,
		PHONE: Phone,
		MEETING: Meeting,
		INFO: Info
	});
	const [statuscolors] = useState({
		OFFER: '#e10087ad',
		PHONE: '#41c4f1',
		MEETING: '#50cd89',
		INFO: '#FFD07F'
	});
	const { swedishLang, englishLang } = useSelector(({ auth }) => auth);
	const selectedLang = useLang()
	const [localLanguage, setLocalLanguage] = useState({})
	const [rating, setRating] = React.useState();
	const [answerModal, setAnswerModal] = React.useState(false);
	const [commentModal, setCommentModal] = React.useState(false);
	const [answersList, setAnswersList] = useState([])
	const [selectAnswer, setSelectAnswer] = useState('');
	const [leadComments, setleadComments] = useState('');
	const [feedBackLists, setFeedBackLists] = React.useState([]);
	const [selectedFeedbackItem, setSelectedFeedbackItem] = useState('');
	const [feedbackStatus, setFeedbackStatus] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		if (selectedLang === 'english') {
			setLocalLanguage(englishLang)
		} else {
			setLocalLanguage(swedishLang)
		}
	}, [selectedLang, swedishLang, englishLang]);

	useEffect(
		() => {
			if (data && data.id) {
				getFeedbackData(data)
				setCurrentUser(data)
				getCustomerData(data);
				getSalesrepData(data);
				getLeadsList(data);
				getAnswers(data)
			}
		},
		[data]
	);

	const getCustomerData = (data, accessToken) => {
		let filter = `?filter[customer_id][eq]=${data.id}&fields=*.*.*.*`;

		getLists('COMPANY_CURRENT_STATUS', filter, accessToken)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					setCustomerData(data[0]);
					// console.log('company data', data[0]);
				} else {
					setCustomerData([]);
				}
			})
			.catch((err) => {
				setCustomerData([]);
				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			});
	};

	// GET LEADS LIST
	const getLeadsList = (data, accessToken) => {
		let filter = `?filter[customer_id][eq]=${data.id}&fields=leadstatus,status_type,ismobilesent,leadscore,contactname,business_name,contactmail,contactphone,leadinfo,id,customer_id.id,customer_id.first_name.customer_id.email,customer_id.title,leaddate,leadcomment,sales_reps,lead_rating&sort=-created_on`;
		getLists('LEADS', filter, accessToken)
			.then(({ data: { data } }) => {
				//alert(JSON.stringify(data));
				if (data && data.length > 0) {
					setLeads(data);
					setVisible(false)
				} else {
					setLeads([]);
				}
			})
			.catch((err) => {
				setLeads([]);
				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			});
	};

	const getSalesrepData = (data, accessToken) => {
		let filter = `?filter[customer_id][eq]=${data.id}&fields=id,name,email`;

		getLists('CUSTOME_SALES_REP', filter, accessToken)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					let reps = [];
					data.map((item, index) => {
						reps.push({ id: item.id, name: item.email });
					});
					setSalesReps(data)
					//console.log('sales data',reps);
					setSuggestion(reps);
					setTags(reps);
					setTempTags(reps)
				} else {
					setSuggestion([]);
				}
			})
			.catch((err) => {
				setSuggestion([]);
				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			});
	};

	const onDelete = useCallback((tagIndex) => {
		setTags(tags.filter((_, i) => i !== tagIndex))
	}, [tags])

	const onAddition = useCallback((newTag) => {
		setTags([...tags, newTag])
	}, [tags])

	const sendRating = () => {
		updateLeads(selectedItem.id, { lead_rating: rating }).then(({ data: { data } }) => {
			setModalInfo({ ...modalInfo, ['lead_rating']: data.lead_rating });
			getLeadsList(currentUser)
			setRatingModal(false)
		})
	}

	const sendFeedback = async () => {
		setLoading(true)
		let emailValue = [];
		if (tags) {
			tags.map((item, index) => {
				emailValue.push(item.name);
			});
		}
		if (emailValue.length == 0) {
			//alert('Please enter at least one E-Mail');
			setMailStatus('NO_REP');
			return;
		}
		let selectedReps = []
		salesReps.map((e1, i) => {
			if (tags.filter(e2 => e2.name === e1.email).length > 0) {
				selectedReps.push(e1.name)
			}
		})
		const values = [];
		let sentmail = []
		try {
			for (let i = 0; i <= tags.length; i++) {
				// values['from'] = `noreply@saljare.se`
				// values['to'] = emailValue;
				if (!tags[i]?.name) {
					return
				}
				values['to'] = [tags[i].name];
				// let content = `<table style="font-size:14px;width:100%;background-color: #dfe9ee7a !important;border-radius: 10px !important;margin-bottom: 0px;padding: 15px;"><tr style="font-weight: 700">Lead Details Below:</tr><tr></tr><tr><td style="font-weight: 600;">Business Name:</td></tr><tr><td>{{businessname}}</td></tr><tr><td style="font-weight: 600;">Contact Name:</td></tr><tr><td>{{contactname}}</td></tr><tr><td style="font-weight: 600;">Contact Mail:</td></tr><tr><td>{{contactmail}}</td></tr><tr><td style="font-weight: 600;">Contact Phone:</td></tr><tr><td>{{contactphone}}</td></tr><tr><td style="font-weight: 600;">Lead Score:</td></tr><tr><td>{{leadscore}}</td></tr><tr><td style="font-weight: 600;">Lead Info:</td></tr><tr><td style="width: 80%">{{leadinfo}}</td></tr><tr><td style="font-weight: 600;">Conversation:</td></tr><tr><td style="width: 80%">{{comments}}</td></tr><tr><td style="font-weight: 600;"><a style="padding: 6px;background: #34a7ce;color: #fff!important;border-radius: 6px;margin-left: 9px;"" href="https://app.saljare.se/" target="_blank" type="button" className="btn btn-default btnBlue">See lead</a></td></tr><tr><td>Feedback</td><td><p><img src="https://api.saljare.se/uploads/salescrm/originals/4294173d-0383-462c-beca-093ea24dc9ac.png" alt="icon" width="300" height="100"/></p></td><tr></table>`;
				let cont = `<div style="display: flex;margin-left:-5px;margin-right:-5px;">
		<div style="flex: 50%;padding: 5px;">
			<table
				style="font-size:14px;width:100%;background-color: #dfe9ee7a !important;border-radius: 10px !important;margin-bottom: 0px;padding: 15px;">
				<tr style="font-weight: 700">Lead Details Below:</tr>
				<tr></tr>
				<tr>
					<td style="font-weight: 600;">Business Name:</td>
				</tr>
				<tr>
					<td>{{businessname}}</td>
				</tr>
				<tr>
					<td style="font-weight: 600;">Contact Name:</td>
				</tr>
				<tr>
					<td>{{contactname}}</td>
				</tr>
				<tr>
					<td style="font-weight: 600;">Contact Phone:</td>
				</tr>
				<tr>
					<td>{{contactphone}}</td>
				</tr>
				<tr>
					<td style="font-weight: 600;">Contact Mail:</td>
				</tr>
				<tr>
					<td>{{contactmail}}</td>
				</tr>
				<tr>
					<td style="font-weight: 600;">Lead Score:</td>
				</tr>
				<tr>
					<td>{{leadscore}}</td>
				</tr>
				<tr>
					<td style="font-weight: 600;">Lead Info:</td>
				</tr>
				<tr>
					<td style="width: 80%">{{leadinfo}}</td>
				</tr>
				<tr>
					<td style="font-weight: 600;">Conversation:</td>
				</tr>
				<tr>
					<td style="width: 80%">{{comments}}</td>
				</tr>
				
				<tr>
					<td></td>
				<tr>
			</table>
		</div>
		<div style="flex: 50%;padding: 5px;">
			<table>
				<tr>
					<td>
						<div style="text-align: center;">Hur var mötet?</div>
						<p>
							<a href="${WEB_URL}/feedback/${selectedItem.id}/${tags[i].id}/${data.id}" target="_blank">
								<img src="https://api.dr.se//uploads//salescrm//originals//a6c3b922-8770-4e89-b1a6-c083f6d46b68.png"
									alt="icon" width="300" height="100" />
							</a>
						</p>
					</td>
				</tr>
			</table>
		</div>
	</div>`;
				values['subject'] = `Feedback`;
				values['body'] = cont;
				let params = {
					...values,
					type: "html",
					data: {
						companyname: customerData.industry || '-',
						businessname: selectedItem.business_name || '-',
						contactname: selectedItem.contactname || '-',
						leadscore: selectedItem.leadscore || '-',
						leadstatus: selectedItem.leadstatus || '-',
						contactmail: selectedItem.contactmail || '-',
						contactphone: selectedItem.contactphone || '-',
						leadinfo: selectedItem.leadinfo || '-',
						comments: leadChatComments || '-'
					}
				};
				postMail(params)
					.then(({ data: { data } }) => {
						updateLeads(selectedItem.id, { ismobilesent: 'SENT', sales_reps: selectedReps.toString() })
							.then(({ data: { data } }) => {
								getLeadsList(currentUser)
							})
						setMailStatus(null);
						setFeedbackStatus('SUCCESS')
						setFeedbackModalMail(false);
						setVisible(false);
						setLoading(false)
						let temp = temptags
						setTags(temp)
					})
					.catch((err) => {
						let temp = temptags
						setTags(temp)
						setLoading(false)
						throw err;
					});
			}


		} catch (err) {
			throw err;
		}
	};

	const sendEmail = () => {
		setLoading(true)
		let emailValue = [];
		if (tags) {
			tags.map((item, index) => {
				emailValue.push(item.name);
			});
		}

		if (emailValue.length == 0) {
			//alert('Please enter at least one E-Mail');
			setMailStatus('NO_REP');
			return;
		}
		let selectedReps = []
		salesReps.map((e1, i) => {
			if (tags.filter(e2 => e2.name === e1.email).length > 0) {
				selectedReps.push(e1.name)
			}
		})

		const values = [];
		// values['from'] = `noreply@saljare.se`
		values['to'] = emailValue;
		let content = '<table style="style="font-size:14px;width:100%;background-color: #dfe9ee7a !important;border-radius: 10px !important;margin-bottom: 0px;padding: 15px;""><tr style="font-weight: 700">Lead Details Below:</tr><tr></tr><tr><td style="font-weight: 600;">Business Name:</td></tr><tr><td>{{businessname}}</td></tr><tr><td style="font-weight: 600;">Contact Name:</td></tr><tr><td>{{contactname}}</td></tr><tr><td style="font-weight: 600;">Contact Mail:</td></tr><tr><td>{{contactmail}}</td></tr><tr><td style="font-weight: 600;">Contact Phone:</td></tr><tr><td>{{contactphone}}</td></tr><tr><td style="font-weight: 600;">Lead Score:</td></tr><tr><td>{{leadscore}}</td></tr><tr><td style="font-weight: 600;">Lead Info:</td></tr><tr><td style="width: 80%">{{leadinfo}}</td></tr><tr><td style="font-weight: 600;">Conversation:</td></tr><tr><td style="width: 80%">{{comments}}</td></tr><tr><td style="font-weight: 600;"><a style="padding: 6px;background: #34a7ce;color: #fff!important;border-radius: 6px;margin-left: 9px;" href="https://app.saljare.se/" target="_blank" type="button">See lead</a></td></tr></table>';
		values['body'] = content;
		values['subject'] = `New Lead`;
		let params = {
			...values,
			type: "html",
			data: {
				companyname: customerData.industry || '-',
				businessname: selectedItem.business_name || '-',
				contactname: selectedItem.contactname || '-',
				leadscore: selectedItem.leadscore || '-',
				leadstatus: selectedItem.leadstatus || '-',
				contactmail: selectedItem.contactmail || '-',
				contactphone: selectedItem.contactphone || '-',
				leadinfo: selectedItem.leadinfo || '-',
				comments: leadChatComments || '-'
			}
		};
		// console.log('params', params);
		postMail(params)
			.then(({ data: { data } }) => {
				//alert(JSON.stringify(data));
				// setTags([]);
				//alert('Mail sent successfully');
				updateLeads(selectedItem.id, { ismobilesent: 'SENT', sales_reps: selectedReps.toString(), salesreps: selectedReps.toString() })
					.then(({ data: { data } }) => {
						getLeadsList(currentUser)
					})
				setMailStatus('SUCCESS');
				setEmailModal(false);
				setVisible(false);
				setLoading(false);
				let temp = temptags
				setTags(temp)
			})
			.catch((err) => {
				let temp = temptags
				setTags(temp);
				setLoading(false)
				throw err;
			});

	};

	const submitAnswer = () => {
		const values = [];
		let emails = [];

		if (tags) {
			tags.map((item, index) => {
				emails.push(item.name);
			});
		}
		//mailto:comment@saljare.se
		values['to'] = ['svar@saljare.se', ...emails];
		// values['cc'] = ['anandsdn@gmail.com'];
		let content = '<table style="font-size: 14px;width:100%"><tr><td style="width: 80%">{{selectAnswer}}</td></tr></table>';
		values['body'] = content;
		values['subject'] = `Answers`;

		let params = {
			...values,
			type: "html",
			data: {
				selectAnswer: selectAnswer || '-'
			}
		};

		postMail(params)
			.then(({ data: { data } }) => {
				//alert(JSON.stringify(data));
				// setTags([]);
				//alert('Mail sent successfully');
				// updateLeads(selectedItem.id, { sales_reps: selectedReps.toString() });
				setMailStatus('SUCCESS');
				setAnswerModal(false);
			})
			.catch((err) => {
				throw err;
			});
	}
	const sendComment = () => {
		let emailValue = ["comment@saljare.se"];

		const values = [];
		//mailto:comment@saljare.se
		values['to'] = emailValue;
		let content = '<table style="<style="font-size:14px;width:100%;background-color: #dfe9ee7a !important;border-radius: 10px !important;margin-bottom: 0px;padding: 15px;""><tr style="font-weight: 700">Lead Details Below:</tr><tr></tr><tr><td style="font-weight: 600;">Business Name:</td></tr><tr><td>{{businessname}}</td></tr><tr><td style="font-weight: 600;">Contact Name:</td></tr><tr><td>{{contactname}}</td></tr><tr><td style="font-weight: 600;">Contact Mail:</td></tr><tr><td>{{contactmail}}</td></tr><tr><td style="font-weight: 600;">Comments:</td></tr><tr><td style="width: 80%;font-weight:bold;">{{comments}}</td></tr><tr><td style="font-weight: 600;"><a style="padding: 6px;background: #34a7ce;color: #fff!important;border-radius: 6px;margin-left: 9px;"" href="https://app.saljare.se/" target="_blank" type="button" className="btn btn-default btnBlue">See lead</a></td></tr></table>';
		values['body'] = content;
		values['subject'] = `Comments`;

		let params = {
			...values,
			type: "html",
			data: {
				companyname: customerData.industry || '-',
				businessname: selectedItem.business_name || '-',
				contactname: selectedItem.contactname || '-',
				leadscore: selectedItem.leadscore || '-',
				leadstatus: selectedItem.leadstatus || '-',
				contactmail: selectedItem.contactmail || '-',
				contactphone: selectedItem.contactphone || '-',
				leadinfo: selectedItem.leadinfo || '-',
				comments: leadComments || '-'
			}
		};

		// console.log('params', params);
		postMail(params)
			.then(({ data: { data } }) => {
				//alert(JSON.stringify(data));
				setleadComments('');
				//alert('Mail sent successfully');
				setMailStatus('SUCCESS');
				setCommentModal(false);
				setVisible(false)
			})
			.catch((err) => {
				throw err;
			});

	};


	const openModal = (data) => {
		let filter = `?filter[lead][eq]=${data.id}&fields=*`

		getLists('LEAD_CHATS', filter)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					setLeadChat(data);
				} else {
					setLeadChat([]);
				}
			})
			.catch((err) => {
				setLeadChat([]);
				throw err;
			})
	};

	const getAnswers = () => {
		let filter = `?sort=-id`;
		getLists('ANSWERS', filter)
			.then(({ data: { data } }) => {
				//console.log('Sales reps', data);

				if (data) {
					setAnswersList(data);
				} else {
					setAnswersList([]);
				}
			})
			.catch((err) => {
				throw err;
			});
	};



	const getFeedbackData = (data) => {
		let filter = `?filter[customer][eq]=${data.id}&fields=id,feedback,feedback_comment,lead,user`;
		getFeedback('FEEDBACK', filter)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					setFeedBackLists(data)
				}
			})
			.catch((err) => {
				throw err;
			});
	};

	const getFeedBackObj = (item) => {
		let response = feedBackLists.filter((row) => row.lead === item);
		return response
	}

	const getFeedBack = (item) => {
		let response = FEEDBACK_STATUS.filter((row) => row.id === parseInt(item));
		return response?.[0] ? response[0].value : ''
	}

	const Listview = () => (
		<div>
			{/* begin::Header
			<div className="card-header border-0 pt-5">
				
				 <div className='align-items-end' style={{display:'flex', flexDirection:'column-reverse'}}>
					<a onClick={()=>{alert('dfsf')}} className="btn btn-primary"><i className="fas fa-download fs-4 me-2"></i> Report</a>
				</div> 
				
				<div className="card-toolbar" />
			</div>
			{/* end::Header */}
			{/* begin::Body */}
			{ mailstatus === 'SUCCESS' || feedbackStatus === 'SUCCESS' &&
				<div className="toast show" role="alert" style={{ position: 'absolute', top: 80, right: 20 }} aria-live="assertive" aria-atomic="true">
					<div className="toast-header">
						<strong className="me-auto"></strong>
						<button type="button" onClick={() => mailstatus ? setMailStatus(null) : setFeedbackStatus(null)} className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
					</div>
					<div className="toast-body ">
						<h6 className="alert alert-success">{mailstatus ? localLanguage.mail_sent : localLanguage.feedback_sent}</h6>
					</div>
				</div>
			}

			<div className="card-body py-3" style={{ backgroundColor: '#f5f8fa' }}>
				<div className="tab-content">
					{/* begin::Tap pane */}
					<div className="tab-pane fade show active" id="kt_table_widget_5_tab_1">
						{/* begin::Table container */}
						<div className="table-responsive">
							{/* begin::Table */}
							<table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
								{/* begin::Table head */}
								<thead>
									<tr className="border-0">
										<th className="p-0 w-5px" />
										<th className="p-0 w-50px" />
										<th className="p-0 min-w-150px" />
										<th className="p-0 min-w-140px" />
										<th className="p-0 min-w-140px" />
										<th className="p-0 min-w-100px" />
										<th className="p-0 min-w-150px" />
										<th className="p-0 min-w-100px" />
										<th className="p-0 w-70px" />
									</tr>
								</thead>
								{/* end::Table head */}
								{/* begin::Table body */}
								<tbody>
									{leads?.length > 0 ?
										leads.map((item, index) => (
											<tr style={{
												backgroundColor: item.leadstatus == "WON" ? '#97DBAE' :
													item.leadstatus == "LOST" ? '#F4BBBB' : 'transparent'
											}}>
												<td></td>
												<td>
													<div className="symbol symbol-45px me-2">

														{item.status_type ? (
															<div
																className="card-icon-rounded"
																style={{
																	backgroundColor: item.status_type
																		? statuscolors[item.status_type]
																		: ''
																}}
															>
																<img
																	src={
																		item.status_type ? (
																			statusimages[item.status_type]
																		) : (
																			''
																		)
																	}
																	className="invert-img"
																	alt=""
																/>
															</div>
														) : (
															'-'
														)}

													</div>
												</td>
												<td>
													<span className="text-muted fw-bold d-block">{localLanguage.business_name}</span>
													<p className="text-dark fw-bolder mb-1 fs-6">
														{item.business_name}
													</p>
												</td><td>
													<span className="text-muted fw-bold d-block">{item.sales_reps ? localLanguage.sales_rep : null}</span>
													<p className="text-dark fw-bolder mb-1 fs-6">
														{item.sales_reps}
													</p>
												</td>
												<td>
													<span className="text-muted fw-bold d-block">{localLanguage.contact_name}</span>
													<p className="text-dark fw-bolder mb-1 fs-6">{item.contactname}</p>
												</td>
												<td>
													<span className="text-muted fw-bold d-block">{localLanguage.feedback}</span>
													<p className="text-dark fw-bolder mb-1 fs-6">{getFeedBackObj(item.id).map((obj, i) => (
														<div>{getFeedBack(obj.feedback)} {obj.feedback ? <span className="qus" onClick={() => {
															setSelectedFeedbackItem(obj.feedback_comment);
															setFeedbackModal(true);
														}}>?</span> : ''}</div>
													))} </p>
												</td>
												<td>
													<span className="text-muted fw-bold d-block">
														{localLanguage.estimated_score}
													</span>
													<p className="text-dark fw-bolder mb-1 fs-6">{item.leadscore}</p>
												</td>

												<td>
													<button type="button" onClick={() => { setRating(''); setSelectedItem(item); setModalInfo(item); setVisible(true); openModal(item); }} className="btn btn-primary btnBlue">Se leadet</button>
												</td>

												<td>
													<span className="text-muted fw-bold d-block">{localLanguage.lead_status}</span>
													<p className="text-dark fw-bolder mb-1 fs-6">{item.status_type}</p>
												</td>
												<td>
													<span className="text-muted fw-bold d-block">{localLanguage.email}</span>
													<div onClick={() => { setRating(''); setSelectedItem(item); setVisible(false); openModal(item); setEmailModal(true); }}>
														<img
															style={{ cursor: 'pointer', width: item.ismobilesent == 'SENT' ? 31 : 26, marginLeft: 5 }}
															src={item.ismobilesent == 'SENT' ? Email : EmailNotSent}
															className="h-50 align-self-center"
															alt=""
														/>
													</div>
												</td>
												<td>
													<span className="text-muted fw-bold d-block">{localLanguage.feedback}</span>
													<div onClick={() => { setRating(''); setSelectedItem(item); setVisible(false); setLoading(false); openModal(item); setFeedbackModalMail(true); }}>
														<img
															style={{ cursor: 'pointer', width: 31, marginLeft: 5 }}
															src={FeedbackImg}
															className="h-50 align-self-center"
															alt=""
														/>
													</div>
												</td>
												<td></td>
											</tr>
										))
										:
										<tr><td style={{ width: '100%' }}>{localLanguage.no_records}</td></tr>
									}
								</tbody>
								{/* end::Table body */}
							</table>
						</div>
						{/* end::Table */}
					</div>
					{/* end::Tap pane */}
				</div>
			</div>


			{/* end::Body */}


		</div>

	);

	useEffect(() => {
		if (leadStatus != '') {
			updateLeadData();
		}
	}, [leadStatus]);

	useEffect(() => {
		let comnts = '';
		setLeadChatComments('');
		if (leadchat) {
			comnts += '<ul>';
			leadchat.map((item, index) => {
				index % 2 ?
					comnts += '<li style="list-style-position: inside;letter-spacing: 1px;">' + (item.comment) + '</li>' :
					comnts += '<li style="letter-spacing: 1px;">' + (item.comment) + '</li>'

			});
			comnts += '</ul>';
			setLeadChatComments(comnts);
		}
	}, [leadchat]);

	const updateLeadData = () => {
		let param = {
			leadcomment: leadComment,
		}
		if (leadStatus) {
			param.leadstatus = leadStatus
		}
		if (leadComment) {
			param.leadcomment = leadComment
		}
		if (!leadStatus) {
			return
		}
		updateLeads(modalInfo.id, param)
			.then(({ data: { data } }) => {
				setModalInfo([])
				setLeadComment('')
				setLeadStatus('');
				getLeadsList(currentUser)

			})
			.catch((err) => {
				setLeadComment('')
				setLeadStatus('')
				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			})
	}

	const IncNum = () => {
		setRating(Number(rating) + 1);
	};
	const DecNum = () => {
		if (rating > 0) setRating(Number(rating) - 1);
		else {
			setRating(0);
			alert("min limit reached");
		}
	};

	return (
		<div>
			<h2 className="mb-10">{localLanguage.list}</h2>
			<Listview />

			{/* start::Email modal */}

			<Modal
				className="modal fade"
				id="kt_modal_select_location"
				data-backdrop="static"
				tabIndex={-1}
				role="dialog"
				show={emailModal}
				dialogClassName="modal-lg"
				aria-hidden="true"
			>

				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}> Mail to </h3>
						<div className="btn btn-icon btn-sm btn-light-primary" onClick={() => setEmailModal(false)}>
							<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
						</div>
					</div>
					<div className="modal-body">
						<ReactTags
							ref={reactTags}
							tags={tags}
							suggestions={suggestions}
							onDelete={onDelete}
							onAddition={onAddition}
							placeholder="Ex: abc@gmail.com, xyz@gmail.com"
						/>
						{mailstatus == "NO_REP" ? (
							<div className='mt-5 mb-lg-15 alert alert-danger'>
								<div className='alert-text font-weight-bold'>{localLanguage.valid_sales_rep}</div>
							</div>
						) : null}
						{mailstatus == "SUCCESS" ? (
							<div className='mt-5 mb-lg-15 alert alert-success'>
								<div className='alert-text font-weight-bold'>{localLanguage.mail_sent}</div>
							</div>
						) : null}
					</div>
					<div className="modal-footer">
						<a disabled={loading} onClick={() => sendEmail()} className="btn btn-primary">
							{loading && (
								<CircularProgress className={"progressbar"} />
							)}{localLanguage.send}</a>

					</div>
				</div>

			</Modal>
			{/* start::Feedback modal */}

			<Modal
				className="modal fade"
				id="kt_modal_select_location"
				data-backdrop="static"
				tabIndex={-1}
				role="dialog"
				show={feedbackModalMail}
				dialogClassName="modal-lg"
				aria-hidden="true"
			>

				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}> {localLanguage.feedback} </h3>
						<div className="btn btn-icon btn-sm btn-light-primary" onClick={() => {
							setFeedbackModalMail(false);
						}}>
							<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
						</div>
					</div>
					<div className="modal-body">
						<ReactTags
							ref={reactTags}
							tags={tags}
							suggestions={suggestions}
							onDelete={onDelete}
							onAddition={onAddition}
							placeholder="Ex: abc@gmail.com, xyz@gmail.com"
						/>
						{feedbackStatus == "NO_REP" ? (
							<div className='mt-5 mb-lg-15 alert alert-danger'>
								<div className='alert-text font-weight-bold'>{localLanguage.valid_sales_rep}</div>
							</div>
						) : null}
						{feedbackStatus == "SUCCESS" ? (
							<div className='mt-5 mb-lg-15 alert alert-success'>
								<div className='alert-text font-weight-bold'>{localLanguage.mail_sent}</div>
							</div>
						) : null}
					</div>
					<div className="modal-footer">

						<a disabled={loading} onClick={() => {
							sendFeedback()
						}} className="btn btn-primary">{loading && (
							<CircularProgress className={"progressbar"} />
						)}{localLanguage.send}</a>

					</div>
				</div>

			</Modal>
			{/* start::Feedback modal */}

			<Modal
				className="modal fade"
				id="kt_modal_select_location"
				data-backdrop="static"
				tabIndex={-1}
				role="dialog"
				show={feedbackModal}
				dialogClassName="modal-lg"
				aria-hidden="true"
			>

				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}> {localLanguage.feedback} </h3>
						<div className="btn btn-icon btn-sm btn-light-primary" onClick={() => {
							setFeedbackModal(false);
							setSelectedFeedbackItem('')
						}}>
							<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
						</div>
					</div>
					<div className="modal-body">
						{selectedFeedbackItem}
					</div>
					<div className="modal-footer">
						<a onClick={() => {
							sendFeedback()
						}} className="btn btn-primary">{localLanguage.cancel_btn}</a>

					</div>
				</div>

			</Modal>


			{/* begin::Modal */}
			<Modal
				className="modal fade"
				id="kt_modal_select_location"
				data-backdrop="static"
				tabIndex={-1}
				role="dialog"
				show={visible}
				dialogClassName="modal-lg"
				aria-hidden="true"
			// onHide={dissmissLocation}
			>
				<div className="modal-content">
					<div className="modal-header list-header" style={{ backgroundColor: modalInfo && (modalInfo.leadstatus == "" || modalInfo.leadstatus == null) ? 'transparent' : modalInfo.leadstatus == "WON" ? '#50cd89' : '#FF8080' }}>
						<h3 className="modal-title" style={{ textAlign: 'center', width: '100%', marginLeft: 54 }}> {modalInfo && modalInfo.business_name}</h3>
						<div className="btn btn-icon btn-sm btn-light-primary" onClick={() => setVisible(false)}>
							<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
						</div>
					</div>
					<div className="modal-body">
						{
							!answerModal &&
							<div className='row g-5'>
								<div className="col-xl-6">
									{modalInfo && modalInfo.leadstatus == "WON" && <a style={{ height: 40 }} className="btn btn-success color-white ms-2 btnGreen btn-hover-scale" disabled="true">{localLanguage.approved}</a>}
									{modalInfo && modalInfo.leadstatus == "LOST" && <a style={{ height: 40 }} className="btn btn-danger ms-2" disabled="true">{localLanguage.denied}</a>}
									{modalInfo && (modalInfo.leadstatus == "" || modalInfo.leadstatus == null) && <div>
										<a onClick={() => setLeadStatus('WON')} style={{ height: 40 }} className="btn btn-success color-white ms-2 btnGreen btn-hover-scale" disabled="true">{localLanguage.accept_lead}</a>
										<a onClick={() => setLeadStatus('LOST')} style={{ height: 40, color: '#ffffff !important' }} className="btn btn-danger ms-2">{localLanguage.deny_lead}</a>
									</div>}
								</div>
								<div className="col-xl-6">
									{modalInfo && modalInfo.status_type == "MEETING" &&
										<div className="rowMedium">
											<div className="card-icon-rounded" style={{ backgroundColor: modalInfo && modalInfo.status_type ? statuscolors[modalInfo.status_type] : '#50cd89', marginRight: 20 }}>
												<img src={Meeting} alt="" className="invert-img" />
											</div>
											<h5>{localLanguage.want_meeting}</h5>
										</div>
									}

									{modalInfo && modalInfo.status_type == "PHONE" &&
										<div className="rowMedium">
											<div className="card-icon-rounded" style={{ backgroundColor: modalInfo && modalInfo.status_type ? statuscolors[modalInfo.status_type] : '#50cd89', marginRight: 20 }}>
												<img src={Phone} alt="" className="invert-img" />
											</div>
											<h5>{localLanguage.want_called}</h5>
										</div>
									}
									{modalInfo && modalInfo.status_type == "INFO" &&
										<div className="rowMedium">
											<div className="card-icon-rounded" style={{ backgroundColor: modalInfo && modalInfo.status_type ? statuscolors[modalInfo.status_type] : '#50cd89', marginRight: 20 }}>
												<img src={Info} alt="" className="invert-img" />
											</div>
											<h5>{localLanguage.want_more_info}</h5>
										</div>
									}
								</div>
							</div>

						}
						<div className="row g-5 g-xl-8">
							{
								!answerModal &&
								<div className="col-xl-6 card-grey mb-5" style={{ backgroundColor: '#b7cfe0 !important' }}>
									<div className='flex-row mb-8 mt-5'>
										<div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
											<i className='bi bi-building fs-3' style={{ color: '#fff' }}></i>
										</div>

										<div className='ms-5' style={{ marginTop: -5 }}>
											<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.business_name}</div>
											<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.business_name}</a>
										</div>
									</div>

									<div className='flex-row mb-8'>
										<div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
											<i className='bi bi-activity fs-3' style={{ color: '#fff' }}></i>
										</div>

										<div className='ms-5' style={{ marginTop: -5 }}>
											<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.lead_score}</div>
											<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.leadscore}</a>
										</div>

									</div>

									<div className='flex-row mb-8'>
										<div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
											<i className='bi bi-person fs-3' style={{ color: '#fff' }}></i>
										</div>
										<div className='ms-5' style={{ marginTop: -5 }}>
											<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.contact_name}</div>
											<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.contactname}</a>
										</div>
									</div>

									<div className='flex-row mb-8'>
										<div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
											<i className='bi bi-envelope fs-3' style={{ color: '#fff' }}></i>
										</div>
										<div className='ms-5' style={{ marginTop: -5 }}>
											<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.contact_email}</div>
											<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.contactmail}</a>
										</div>
									</div>

									<div className='flex-row mb-8'>
										<div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
											<i className='bi bi-back fs-3' style={{ color: '#fff' }}></i>
										</div>

										<div className='ms-5' style={{ marginTop: -5 }}>
											<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.lead_status}</div>
											<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.leadstatus ? "WON" : "NA"}</a>
										</div>
									</div>

									<div className='flex-row mb-8'>
										<div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
											<i className='bi bi-calendar-date fs-3' style={{ color: '#fff' }}></i>
										</div>

										<div className='ms-5' style={{ marginTop: -5 }}>
											<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.created_on}</div>
											<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.leaddate}</a>
										</div>
									</div>
									<div className='flex-row mb-8'>
										<div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
											<i className='bi bi-phone fs-3' style={{ color: '#fff' }}></i>
										</div>

										<div className='ms-5' style={{ marginTop: -5 }}>
											<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.created_phone}</div>
											<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.contactphone}</a>
										</div>
									</div>
									{/* <div className='flex-row mb-8'>
										<div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
											<i className='bi bi-phone fs-3' style={{ color: '#fff' }}></i>
										</div>

										<div className='ms-5' style={{ marginTop: -5 }}>
											<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.position}</div>
											<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.customer_id?.title ? modalInfo.customer_id?.title : '-'}</a>
										</div>
									</div> */}
									{/* <div className='flex-row mb-8'>
										<div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
											<i className='bi bi-phone fs-3' style={{ color: '#fff' }}></i>
										</div>

										<div className='ms-5' style={{ marginTop: -5 }}>
											<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.position}</div>
											<a className='fs-5 fw-bold text-gray-900'>{'CEO'}</a>
										</div>
									</div> */}
									{leadchat && leadchat.length == 0 && modalInfo.leadcomment != null &&
										<div className='flex-row mb-8'>
											<div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
												<i className='bi bi-chat fs-3' style={{ color: '#fff' }}></i>
											</div>

											<div className='ms-5' style={{ marginTop: -5 }}>
												<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.lead_conversation}</div>
												<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.leadcomment}</a>
											</div>
										</div>
									}

								</div>
							}
							<div className={`${answerModal ? 'col-xl-12' : 'col-xl-6'} mb-5`}>

								<div className='fs-5 fw-bolder text-gray-600 mb-5'>{localLanguage.history}</div>

								{
									leadchat.map((item, index) => {
										return (
											index % 2 ?
												<div className={'flex-row mb-8'} key={index} style={{ justifyContent: 'flex-end' }}>
													<div className='ms-5 chatbox'>
														<a className='fs-6 fw-bold text-gray-900'>{item.comment}</a>
													</div>
													<div className='card-icon-rounded-small' style={{ backgroundColor: '#34a7ce', padding: 5 }}>
														<img
															alt='Logo'
															className='h-20px logo'
															src={"../media/logos/logo.png"}
														/>
													</div>


												</div>
												:
												<div className={'flex-row mb-8'} key={index} >

													{/* <div className='card-icon-rounded-small' style={{ backgroundColor: '#17D7A0', padding: 5 }}>
														<i className={ 'bi bi-person-fill fs-3'} style={{ color: '#fff' }}></i>
													*/}
													<div className='card-icon-rounded-small' style={{ backgroundColor: '#34a7ce', padding: 5 }}>
														<img
															alt='Logo'
															className='h-20px logo'
															src={"../media/logos/logo.png"}
														/>
													</div>

													<div className='ms-5 chatbox'>
														<a className='fs-6 fw-bold text-gray-900 comnt'>{item.comment}</a>
													</div>
												</div>
										)
									})
								}
								{
									answerModal &&
									<>
										<div className='row mb-10'>
											<div className='col-lg-12 col-xl-12'>
												<select
													className='form-select form-select-solid'
													name='layout-builder[layout][header][width]'
													onChange={(e) =>
														setSelectAnswer(e.target.value)
													}
												>
													<option value={''}>{'Choose answer '}</option>
													{
														answersList.length > 0
														&& answersList.map((item, i) => {
															return (
																<option key={i} value={item.answer}>{item.answer}</option>
															)
														})
													}
												</select>
											</div>
										</div>
										<div className='row mb-10'>
											<div className='col-lg-12 col-xl-12'>
												<ReactTags
													ref={reactTags}
													tags={tags}
													suggestions={suggestions}
													onDelete={onDelete}
													onAddition={onAddition}
													placeholder=""
												/>
											</div>
										</div>
									</>}
							</div>
							<div className={`${answerModal ? 'btm-btn j-center' : 'btm-btn'} mb-5`}>
								{
									answerModal ? <>
										<div className='ms-5'>
											<a onClick={() => { setAnswerModal(false) }} className="btn btn-success color-white ms-2 btnDarkBlue round" disabled="true">{localLanguage.cancel_btn}</a>
										</div><div className='ms-5'>
											<a onClick={() => { submitAnswer() }} className="btn btn-success color-white ms-2 btnDarkBlue round" disabled="true">{localLanguage.send}</a>
										</div></>
										:
										<>
											<div className='ms-5'>
												<a onClick={() => { setAnswerModal(true); }} className="btn btn-success color-white ms-2 btnDarkBlue" disabled="true">{localLanguage.answer}</a>
											</div>
											<div className='ms-5'>
												<a onClick={() => { setRating(modalInfo.lead_rating ? modalInfo.lead_rating : 0); setRatingModal(true); }} className="btn btn-success color-white ms-2 btnDarkBlue" disabled="true">{modalInfo.lead_rating ? modalInfo.lead_rating : 0}</a>
											</div>
											<div className='ms-5'>
												<a onClick={() => { setVisible(false); setCommentModal(true); }} className="btn btn-success color-white ms-2 btnBlue" disabled="true">{localLanguage.comment}</a>
											</div>
											<div className='ms-5'>
												<a onClick={() => { setVisible(false); setLoading(false); setEmailModal(true); }} className="btn btn-success color-white ms-2 btnBlue" disabled="true">{localLanguage.send_lead}</a>
											</div>
										</>
								}
							</div>
						</div>
					</div>
				</div>
			</Modal>
			{/* start::Rating modal */}
			<Modal
				className="modal fade"
				id="kt_modal_select_location"
				data-backdrop="static"
				tabIndex={-1}
				role="dialog"
				show={ratingModal}
				dialogClassName="modal-lg"
				aria-hidden="true"
			>

				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}> {localLanguage.rating} </h3>
						<div className="btn btn-icon btn-sm btn-light-primary" onClick={() => setRatingModal(false)}>
							<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
						</div>
					</div>
					<div className="modal-body">
						<div className="main_div">
							<div className="btn_div">
								<Button className="rating-icon inc-icon" onClick={IncNum}>
									<AddIcon />
								</Button>
								<span>
									{rating}
								</span>
								<Button className="rating-icon remove-icon" onClick={DecNum}>
									<RemoveIcon />
								</Button>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<a onClick={() => sendRating()} className="btn btn-primary">{localLanguage.send}</a>

					</div>
				</div>

			</Modal>


			{/* begin::Modal */}

			{/* start::Comment modal */}
			<Modal
				className="modal fade"
				id="kt_modal_select_location"
				data-backdrop="static"
				tabIndex={-1}
				role="dialog"
				show={commentModal}
				dialogClassName="modal-lg"
				aria-hidden="true"
			>

				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}> {localLanguage.comment} </h3>
						<div className="btn btn-icon btn-sm btn-light-primary" onClick={() => setCommentModal(false)}>
							<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
						</div>
					</div>
					<div className="modal-body">
						<div className="">
							<textarea
								rows="4"
								className="comments"
								onChange={(e) =>
									setleadComments(e.target.value)
								}
							>
							</textarea>
						</div>
					</div>
					<div className="modal-footer">
						<a onClick={() => sendComment()} className="btn btn-primary">{localLanguage.send}</a>

					</div>
				</div>

			</Modal>


			{/* begin::Modal */}
		</div>
	);
}

export { ListWrapper };
