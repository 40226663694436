
export default function LIstCard({
    leads,
    localLanguage,
    EmailImg,
    setModalInfo,
    setVisible,
    RemoveImg,
    deleteItem,
    PhoneImg,
    PhoneImgDark,
    sendPhonelist,
    openEmailModal,
    setSelectedItem
}) {
    return (
        <div className="card-body py-3" style={{ backgroundColor: '#f5f8fa' }}>
            <div className="tab-content">
                {/* begin::Tap pane */}
                <div className="tab-pane fade show active" id="kt_table_widget_5_tab_1">
                    {/* begin::Table container */}
                    <div className="table-responsive">
                        {/* begin::Table */}
                        <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                            {/* begin::Table head */}
                            {
                                leads?.length > 0 &&
                                <thead>
                                    <tr className="border-0">
                                        <th className="p-0 min-w-150px" ><span className="text-muted fw-bold d-block">{localLanguage.business_name}</span></th>
                                        <th className="p-0 min-w-150px" ><span className="text-muted fw-bold d-block">{localLanguage.contact_name}</span></th>
                                        <th className="p-0 min-w-150px" ><span className="text-muted fw-bold d-block">{localLanguage.lead_info}</span></th>
                                        <th className="p-0 min-w-150px" ><span className="text-muted fw-bold d-block">{localLanguage.email}</span></th>
                                        <th className="p-0 min-w-150px" ><span className="text-muted fw-bold d-block">{localLanguage.Send_Phone_list}</span></th>
                                        <th className="p-0 min-w-150px" ><span className="text-muted fw-bold d-block">{localLanguage.remove}</span></th>
                                    </tr>
                                </thead>
                            }
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {leads?.length > 0 ?
                                    leads.map((item, index) => (
                                        <tr>
                                            <td>
                                                <p className="text-dark fw-bolder mb-1 fs-6">
                                                    {item?.lead_id?.business_name}
                                                </p>
                                            </td>
                                            <td>
                                                <p className="text-dark fw-bolder mb-1 fs-6">{item?.lead_id?.contactname}</p>
                                            </td>
                                            <td>
                                                <button type="button" onClick={() => { setVisible(true); setModalInfo(item.lead_id); }} className="btn btn-primary btnBlue">{localLanguage.see_lead_btn}</button>
                                            </td>
                                            <td>
                                                <div>
                                                    <img
                                                        style={{ cursor: 'pointer', marginLeft: 5, width: 30 }}
                                                        src={EmailImg}
                                                        className="h-50 align-self-center"
                                                        alt=""
                                                        onClick={() => openEmailModal(item)}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <img
                                                        style={{ cursor: 'pointer', marginLeft: 5, width: 30 }}
                                                        src={item.isaddphonelist === 'YES' ? PhoneImgDark : PhoneImg}
                                                        className={`h-50 align-self-center ${item.isaddphonelist === 'YES' ? 'disabled' : ''}`}
                                                        alt=""
                                                        onClick={() => { setSelectedItem({}); sendPhonelist(item); }}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <img
                                                        onClick={() => deleteItem(item.id)}
                                                        style={{ cursor: 'pointer', marginLeft: 5, width: 30 }}
                                                        src={RemoveImg}
                                                        className="h-50 align-self-center"
                                                        alt=""
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr><td style={{ width: '100%' }}>{localLanguage.no_records}</td></tr>
                                }
                            </tbody>
                            {/* end::Table body */}
                        </table>
                    </div>
                    {/* end::Table */}
                </div>
                {/* end::Tap pane */}
            </div>
        </div>
    )
}
