import { WEB_URL, FEEDBACK_STATUS } from '../../../../_metronic/helpers/components/constant'
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '../../../../_metronic/helpers';
import { getLists, postMail, updateLeads, getFeedback, insert } from '../redux/DashboardCrud';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactTags from 'react-tag-autocomplete';
import Phone from './phone.png';
import Discount from './discount.png';
import Meeting from './meeting.png';
import Info from './information.png';
import { gapi } from "gapi-script";
import moment from "moment";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { addApi, updateApi } from '../../../../_metronic/helpers/components/utils'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

// const CLIENT_ID = '1040969583552-2vrsbckmn343pt26bn0p544aam1rgakg.apps.googleusercontent.com';
// const CLIENT_ID = '497233900907-6i09qf6mcbund0423c3n2aegkbnosgi4.apps.googleusercontent.com';
// const SECRET = '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';
// const apiKey = 'AIzaSyDZrvZ65vPaBvehWp8jkHQhWv6-BGbGlMk';
// const refresh_token = '1//0gosKOCufILTlCgYIARAAGBASNwF-L9IraMW3jUtoxXO1aNmVGVHBbTtNztsXbGhavtGDz1NyCRhGJHIZgG4RDB3QawyzttG2Ofw';
// const SCOPES = "https://www.googleapis.com/auth/calendar";
// const discoveryDocs = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];

export default function LIstCard({
    leads,
    localLanguage,
    // statuscolors,
    // statusimages,
    data,
    isDashboard,
    setAccessToken,
    page,
    feedBackLists,
    salesRepsList,
    answersList,
    getLeadsListFilter,
}) {
    const [rating, setRating] = React.useState();
    const [answerModal, setAnswerModal] = React.useState(false);
    // const [answersList, setAnswersList] = useState([])
    const [selectAnswer, setSelectAnswer] = useState('');
    const [leadComments, setleadComments] = useState('');
    // const [feedBackLists, setFeedBackLists] = React.useState([]);
    const [selectedFeedbackItem, setSelectedFeedbackItem] = useState('');
    const [feedbackStatus, setFeedbackStatus] = React.useState(null);
    const [feedbackModal, setFeedbackModal] = React.useState(false);
    const [feedbackModalMail, setFeedbackModalMail] = React.useState(false);
    const reactTags = useRef();
    const [tags, setTags] = React.useState(salesRepsList);
    const [temptags, setTempTags] = React.useState(salesRepsList);
    const [suggestions, setSuggestion] = React.useState(salesRepsList);
    const [currentUser, setCurrentUser] = useState({})
    const [mailstatus, setMailStatus] = React.useState(null);
    const [visible, setVisible] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [salesReps, setSalesReps] = useState([])
    const [customerData, setCustomerData] = React.useState({});
    const [leadChatComments, setLeadChatComments] = useState('');
    const [leadchat, setLeadChat] = useState([]);
    const [modalInfo, setModalInfo] = React.useState([]);
    const [commentModal, setCommentModal] = React.useState(false);
    const [leadStatus, setLeadStatus] = useState();
    const [leadStatusNew, setLeadStatusNew] = useState('interested');
    const [meetingstatus, setmeetingstatus] = React.useState('');
    const [emailModal, setEmailModal] = React.useState(false);
    const [status, setStatus] = React.useState(false);
    const [statusimages] = useState({
        "OFFER": Discount,
        "PHONE": Phone,
        "MEETING": Meeting,
        "INFO": Info
    })
    const [statuscolors] = useState({
        "OFFER": '#50cd89',
        "PHONE": '#41c4f1',
        "MEETING": '#50cd89',
        "INFO": '#FFD07F'
    });
    const [showMeetingModal, setshowMeetingModal] = useState(false);
    const [meetingTime, setMeetingTime] = useState('');
    const [meetingEndTime, setMeetingEndTime] = useState('');
    const [meetingtittle, setmeetingtittle] = useState('');
    const [bookmeetingStatus, setBookMeetingStatus] = useState('');
    const [isBookaMeeting, setisBookaMeeting] = useState(false);

    useEffect(
        () => {
            if (data && data.id) {
                // getFeedbackData(data)
                setCurrentUser(data)
                // getCustomerData(data);
                // getSalesrepData(data);
                // getLeadsList(data);
                // getAnswers(data)
            }
        },
        [data]
    );

    useEffect(
        () => {
            if (modalInfo && modalInfo.id) {
                // getFeedbackData(data)
                if (modalInfo.leadstatusnew) {
                    setLeadStatusNew(modalInfo.leadstatusnew)
                }
                // getCustomerData(data);
                // getSalesrepData(data);
                // getLeadsList(data);
                // getAnswers(data)
                setBookMeetingStatus('')
            }
        },
        [modalInfo]
    );


    // const getAnswers = () => {
    //     let filter = `?sort=-id`;
    //     getLists('ANSWERS', filter)
    //         .then(({ data: { data } }) => {
    //             //console.log('Sales reps', data);

    //             if (data) {
    //                 setAnswersList(data);
    //             } else {
    //                 setAnswersList([]);
    //             }
    //         })
    //         .catch((err) => {
    //             throw err;
    //         });
    // };

    // const getCustomerData = (data) => {
    //     let filter = `?filter[customer_id][eq]=${data.id}&fields=*.*.*.*`;

    //     getLists('COMPANY_CURRENT_STATUS', filter)
    //         .then(({ data: { data } }) => {
    //             if (data && data.length > 0) {
    //                 setCustomerData(data[0]);
    //                 // console.log('company data', data[0]);
    //             } else {
    //                 setCustomerData([]);
    //             }
    //         })
    //         .catch((err) => {
    //             setCustomerData([]);
    //             throw err;
    //             // setIsloading(false)
    //             //   setStatus('The login detail is incorrect')
    //         });
    // };


    // const deleteMeeting = (val) => {
    //     let events = [

    //         {
    //             "id": "7ehu6l0bppgnaltkqj9h0h9u34_20231106T043000Z"
    //         }
    //     ]
    //     // console.log(moment(meetingTime).add( moment.duration('05:30') ).utc().format(),"start dataaa", moment(meetingTime).format())
    //     for (let j = 0; j <= events.length; j += 1) {
    //         // console.log("events", events[j].id)
    //         // return
    //         gapi.client
    //             .request({
    //                 path: `https://www.googleapis.com/calendar/v3/calendars/direktreklammote@gmail.com/events/${events[j].id}`,
    //                 method: "DELETE",
    //                 headers: {
    //                     "Content-type": "application/json",
    //                     Authorization: `Bearer ${localStorage.getItem('google_access_token')}`,
    //                 },
    //             })
    //             .then(
    //                 (response) => {
    //                     setLoading(false)
    //                     setmeetingstatus("SUCCESS")
    //                     setMeetingTime('')
    //                     return [true, response];
    //                 },
    //                 function (err) {
    //                     setLoading(false)
    //                     setmeetingstatus("FAIL");
    //                     // loadGoogleSignin(val)
    //                     return [false, err];
    //                 }
    //             );

    //     }
    // }



    const createBookMeeting = (val) => {
        setLoading(true)
        let emailValue = [];
        if (tags) {
            tags.map((item, index) => {
                if (item.name.match(mailformat)) {
                    emailValue.push({ "email": item.name });
                }
            });
        }
        let meeting_time = moment(meetingTime).utc().format();
        // let meeting_endtime = moment(meetingEndTime).utc().format();
        let obj = {
            lead: val.id,
            salesreps: emailValue,
            customer_id: currentUser.id,
            event_type: "manual",
            meeting_start: moment(meeting_time).format("YYYY-MM-DD hh:mm:ss"),
            // meeting_end: moment(meeting_endtime).format("YYYY-MM-DD hh:mm:ss")
        }
        let filter = `?filter[customer_id][eq]=${currentUser.id}&filter[lead][eq]=${val.id}&fields=id`;

        getLists('EVENTS_LIST', filter)
            .then(({ data: { data } }) => {
                if (data?.length === 0) {
                    insert('EVENTS_LIST', obj)
                        .then(({ data: { data } }) => {
                            setBookMeetingStatus('SUCCESS')
                            getLeadsListFilter(currentUser)
                        })
                        .catch((err) => {
                            throw err;
                            // setIsloading(false)
                            //   setStatus('The login detail is incorrect')
                        });
                    setLoading(false)

                } else if (data?.length > 0) {
                    obj.id = data[0].id
                    updateApi('event_lists', obj)
                        .then(({ data: { data } }) => {
                            setBookMeetingStatus('SUCCESS')
                            getLeadsListFilter(currentUser)
                        })
                        .catch((err) => {
                            throw err;
                            // setIsloading(false)
                            //   setStatus('The login detail is incorrect')
                        });
                    setLoading(false)

                } else {
                    setBookMeetingStatus("FAIL")
                    setLoading(false)

                }
            })
            .catch((err) => {
                setLoading(false)

                throw err;
            })


    }
    const sendMeeting = (val) => {


        if (!meetingTime && !val.contactmail) {
            return
        }
        let emailValue = [{ 'email': val.contactmail }];
        // let emailValue = [];
        if (tags) {
            tags.map((item, index) => {
                if (item.name.match(mailformat)) {
                    emailValue.push({ "email": item.name });
                }
            });
        }
        if (tags.length == 0) {
            //alert('Please enter at least one E-Mail');
            setMailStatus('NO_REP');
            return;
        }
        if (isBookaMeeting) {
            createBookMeeting(val);
            return

        }
        setLoading(true)
        let event = {
            summary: meetingtittle,
            start: {
                dateTime: meetingTime,
                timeZone: "Europe/Stockholm",
            },

            end: {
                dateTime: meetingEndTime,
                timeZone: "Europe/Stockholm",
            },
            recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
            conferenceDataVersion: 1,
            resource: {
                conferenceData: {
                    createRequest: { requestId: "test-12334545" }
                },
            },
            conferenceData: {
                "createRequest": {
                    "requestId": "meet-dk8ncv11",
                    "conferenceSolutionKey": {
                        "type": "hangoutsMeet"
                    }
                }
            },
            //,{ 'email': 'ganeshkumar@inspirepro.eu' }
            // attendees: [{ 'email': 'anandsdn@gmail.com' }, { 'email': 'gmahalakshmibe@gmail.com' }],
            attendees: emailValue,
            reminders: {
                useDefault: false,
                overrides: [
                    // { method: "email", minutes: 1 },
                    { method: "popup", minutes: 10 },
                ],
            },
        };

        gapi.client
            .request({
                path: `https://www.googleapis.com/calendar/v3/calendars/direktreklammote@gmail.com/events`,
                method: "POST",
                body: event,
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('google_access_token')}`,
                },
            })
            .then(
                (response) => {
                    setLoading(false)
                    setmeetingstatus("SUCCESS")
                    setMeetingTime('')
                    let obj = {
                        lead: val.id,
                        customer_id: currentUser.id,
                        event_type: "auto",
                        events: response.result
                    }
                    insert('EVENTS_LIST', obj)
                        .then(({ data: { data } }) => {
                            getLeadsListFilter(currentUser)
                        })
                        .catch((err) => {
                            throw err;
                            // setIsloading(false)
                            //   setStatus('The login detail is incorrect')
                        });
                    return [true, response];
                },
                function (err) {
                    setLoading(false)
                    setmeetingstatus("FAIL");
                    // loadGoogleSignin(val)
                    console.log("errrr", err.status)
                    return [false, err];
                }
            );

    }

    // const loadGoogleSignin = (value) => {
    //     gapi.load('client:auth2', () => {

    //         gapi.client.init({
    //             apiKey: apiKey,
    //             clientId: CLIENT_ID,
    //             discoveryDocs: discoveryDocs,
    //             scope: SCOPES
    //         });
    //         gapi.client.load('calendar', 'V3', () => {

    //             let payloadForAccessToken = {
    //                 grant_type: 'refresh_token',
    //                 refresh_token: refresh_token,
    //                 client_id: CLIENT_ID,
    //                 client_secret: SECRET,
    //             };

    //             axios
    //                 .post(`https://oauth2.googleapis.com/token`, payloadForAccessToken, {
    //                     headers: {
    //                         'Content-Type': 'application/json;',
    //                     },
    //                 })
    //                 .then((res) => {
    //                     return res.data;
    //                 })
    //                 .then((res) => {
    //                     localStorage.setItem("google_access_token", res.access_token)
    //                     setAccessToken(res.access_token)
    //                     sendMeeting(value)
    //                 })
    //         });


    //     })
    // }

    const sendNuture = (val) => {

        let obj = {
            status: 'published',
            lead_id: val
        }
        let filter = `?filter[owner][eq]=${data.id}&filter[lead_id][eq]=${val}&fields=id`;
        setLoading(true);
        getLists('NUTURE', filter)
            .then(({ data: { data } }) => {
                if (data?.length === 0) {
                    setLoading(false);
                    insert('NUTURE', obj)
                        .then(({ data: { data } }) => {
                            setStatus('SUCCESS')
                        })
                        .catch((err) => {
                            throw err;
                            // setIsloading(false)
                            //   setStatus('The login detail is incorrect')
                        });
                } else {
                    setLoading(false);
                    setStatus("ALREADY")
                }
            })
            .catch((err) => {
                setStatus("FAIL")
                setLoading(false);
                throw err;
            })

    };


    useEffect(() => {
        let comnts = '';
        setLeadChatComments('');
        if (leadchat) {
            comnts += '<ul>';
            leadchat.map((item, index) => {
                index % 2 ?
                    comnts += '<li style="list-style-position: inside;letter-spacing: 1px;">' + (item.comment) + '</li>' :
                    comnts += '<li style="letter-spacing: 1px;">' + (item.comment) + '</li>'

            });
            comnts += '</ul>';
            setLeadChatComments(comnts);
        }
    }, [leadchat]);

    useEffect(() => {
        if (modalInfo?.id && page === 'newleads') {
            let param = {
                leadstatus: 'WON',
                isread: true
            }
            updateLeadData(param)
        }
    }, [modalInfo, page])

    const updateLeadData = (param) => {
        updateLeads(modalInfo.id, param)
            .then(({ data: { data } }) => {
            })
            .catch((err) => {

                throw err;
            })
    }

    const addManageList = (param) => {
        addApi('manage_calls_phone_meeting', param)
            .then(({ data: { data } }) => {
            })
            .catch((err) => {

                throw err;
            })
    }

    const openModal = (data) => {
        if (page === 'newleads') {

        }
        let filter = `?filter[lead][eq]=${data.id}&fields=comment,id,lead`

        getLists('LEAD_CHATS', filter)
            .then(({ data: { data } }) => {
                if (data && data.length > 0) {
                    setLeadChat(data);
                } else {
                    setLeadChat([]);
                }
            })
            .catch((err) => {
                setLeadChat([]);
                throw err;
            })
    };

    const onDelete = useCallback((tagIndex) => {
        setTags(tags.filter((_, i) => i !== tagIndex))
    }, [tags])

    const onAddition = useCallback((newTag) => {
        setTags([...tags, newTag])
    }, [tags])

    // const getFeedbackData = (data) => {
    //     let filter = `?filter[customer][eq]=${data.id}&fields=id,feedback,feedback_comment,lead,user`;
    //     getFeedback('FEEDBACK', filter)
    //         .then(({ data: { data } }) => {
    //             if (data && data.length > 0) {
    //                 setFeedBackLists(data)
    //             }
    //         })
    //         .catch((err) => {
    //             throw err;
    //         });
    // };

    // console.log("leads", leads)
    const getFeedBackvalue = (item) => {
        let response = FEEDBACK_STATUS.filter((row) => row.id === parseInt(item));
        return response?.[0] ? response[0].value : ''
    }
    const getFeedBackObj = (item) => {
        let response = feedBackLists.filter((row) => row.lead === item);
        return response
    }

    const sendFeedback = async () => {
        setLoading(true)
        let emailValue = [];
        if (tags) {
            tags.map((item, index) => {
                emailValue.push(item.name);
            });
        }
        if (emailValue.length == 0) {
            //alert('Please enter at least one E-Mail');
            setMailStatus('NO_REP');
            return;
        }
        let selectedReps = []
        let selected_Reps = []
        salesReps.map((e1, i) => {
            if (tags.filter(e2 => e2.name === e1.email).length > 0) {
                selectedReps.push(e1.name)
                selected_Reps.push(e1)
            }
        })
        const values = [];
        let sentmail = []
        try {
            for (let i = 0; i <= tags.length; i++) {
                // values['from'] = `noreply@saljare.se`
                // values['to'] = emailValue;
                if (!tags[i]?.name) {
                    return
                }
                values['to'] = [tags[i].name];
                // let content = `<table style="font-size:14px;width:100%;background-color: #dfe9ee7a !important;border-radius: 10px !important;margin-bottom: 0px;padding: 15px;"><tr style="font-weight: 700">Lead Details Below:</tr><tr></tr><tr><td style="font-weight: 600;">Business Name:</td></tr><tr><td>{{businessname}}</td></tr><tr><td style="font-weight: 600;">Contact Name:</td></tr><tr><td>{{contactname}}</td></tr><tr><td style="font-weight: 600;">Contact Mail:</td></tr><tr><td>{{contactmail}}</td></tr><tr><td style="font-weight: 600;">Contact Phone:</td></tr><tr><td>{{contactphone}}</td></tr><tr><td style="font-weight: 600;">Lead Score:</td></tr><tr><td>{{leadscore}}</td></tr><tr><td style="font-weight: 600;">Lead Info:</td></tr><tr><td style="width: 80%">{{leadinfo}}</td></tr><tr><td style="font-weight: 600;">Conversation:</td></tr><tr><td style="width: 80%">{{comments}}</td></tr><tr><td style="font-weight: 600;"><a style="padding: 6px;background: #34a7ce;color: #fff!important;border-radius: 6px;margin-left: 9px;"" href="https://app.saljare.se/" target="_blank" type="button" className="btn btn-default btnBlue">See lead</a></td></tr><tr><td>Feedback</td><td><p><img src="https://api.saljare.se/uploads/salescrm/originals/4294173d-0383-462c-beca-093ea24dc9ac.png" alt="icon" width="300" height="100"/></p></td><tr></table>`;
                let cont = `<div style="display: flex;margin-left:-5px;margin-right:-5px;">
		<div style="flex: 50%;padding: 5px;">
			<table
				style="font-size:14px;width:100%;background-color: #dfe9ee7a !important;border-radius: 10px !important;margin-bottom: 0px;padding: 15px;">
				<tr style="font-weight: 700">Lead Details Below:</tr>
				<tr></tr>
				<tr>
					<td style="font-weight: 600;">Business Name:</td>
				</tr>
				<tr>
					<td>{{businessname}}</td>
				</tr>
				<tr>
					<td style="font-weight: 600;">Contact Name:</td>
				</tr>
				<tr>
					<td>{{contactname}}</td>
				</tr>
				<tr>
					<td style="font-weight: 600;">Contact Phone:</td>
				</tr>
				<tr>
					<td>{{contactphone}}</td>
				</tr>
				<tr>
					<td style="font-weight: 600;">Contact Mail:</td>
				</tr>
				<tr>
					<td>{{contactmail}}</td>
				</tr>
				<tr>
					<td style="font-weight: 600;">Lead Score:</td>
				</tr>
				<tr>
					<td>{{leadscore}}</td>
				</tr>
				<tr>
					<td style="font-weight: 600;">Lead Info:</td>
				</tr>
				<tr>
					<td style="width: 80%">{{leadinfo}}</td>
				</tr>
				<tr>
					<td style="font-weight: 600;">Conversation:</td>
				</tr>
				<tr>
					<td style="width: 80%">{{comments}}</td>
				</tr>
				
				<tr>
					<td></td>
				<tr>
			</table>
		</div>
		<div style="flex: 50%;padding: 5px;">
			<table>
				<tr>
					<td>
						<div style="text-align: center;">Hur var mötet?</div>
						<p>
							<a href="${WEB_URL}/feedback/${selectedItem.id}/${tags[i].id}/${data.id}" target="_blank">
								<img src="https://api.saljare.se/uploads/salescrm/originals/4294173d-0383-462c-beca-093ea24dc9ac.png"
									alt="icon" width="300" height="100" />
							</a>
						</p>
					</td>
				</tr>
			</table>
		</div>
	</div>`;
                values['subject'] = `Feedback`;
                values['body'] = cont;
                let params = {
                    ...values,
                    type: "html",
                    data: {
                        companyname: customerData.industry || '-',
                        businessname: selectedItem.business_name || '-',
                        contactname: selectedItem.contactname || '-',
                        leadscore: selectedItem.leadscore || '-',
                        leadstatus: selectedItem.leadstatus || '-',
                        contactmail: selectedItem.contactmail || '-',
                        contactphone: selectedItem.contactphone || '-',
                        leadinfo: selectedItem.leadinfo || '-',
                        comments: leadChatComments || '-'
                    }
                };
                postMail(params)
                    .then(({ data: { data } }) => {
                        updateLeads(selectedItem.id, { ismobilesent: 'SENT', sales_reps: selectedReps.toString(), salesreps: JSON.stringify(selected_Reps) })
                            .then(({ data: { data } }) => {
                                // getLeadsList(currentUser)
                            })
                        setMailStatus(null);
                        setFeedbackStatus('SUCCESS')
                        setFeedbackModalMail(false);
                        setVisible(false);
                        setLoading(false)
                        let temp = temptags
                        setTags(temp)
                    })
                    .catch((err) => {
                        let temp = temptags
                        setTags(temp)
                        setLoading(false)
                        throw err;
                    });
            }


        } catch (err) {
            throw err;
        }
    }

    const sendEmail = () => {
        setLoading(true)
        let emailValue = [];
        if (tags) {
            tags.map((item, index) => {
                emailValue.push(item.name);
            });
        }

        if (emailValue.length == 0) {
            //alert('Please enter at least one E-Mail');
            setMailStatus('NO_REP');
            return;
        }
        let selectedReps = []
        let selected_Reps = []
        salesReps.map((e1, i) => {
            if (tags.filter(e2 => e2.name === e1.email).length > 0) {
                selectedReps.push(e1.name)
                selected_Reps.push(e1)
            }
        })
        const values = [];
        // values['from'] = `noreply@saljare.se`
        values['to'] = emailValue;
        let content = '<table style="style="font-size:14px;width:100%;background-color: #dfe9ee7a !important;border-radius: 10px !important;margin-bottom: 0px;padding: 15px;""><tr style="font-weight: 700">Lead Details Below:</tr><tr></tr><tr><td style="font-weight: 600;">Business Name:</td></tr><tr><td>{{businessname}}</td></tr><tr><td style="font-weight: 600;">Contact Name:</td></tr><tr><td>{{contactname}}</td></tr><tr><td style="font-weight: 600;">Contact Mail:</td></tr><tr><td>{{contactmail}}</td></tr><tr><td style="font-weight: 600;">Contact Phone:</td></tr><tr><td>{{contactphone}}</td></tr><tr><td style="font-weight: 600;">Lead Score:</td></tr><tr><td>{{leadscore}}</td></tr><tr><td style="font-weight: 600;">Lead Info:</td></tr><tr><td style="width: 80%">{{leadinfo}}</td></tr><tr><td style="font-weight: 600;">Conversation:</td></tr><tr><td style="width: 80%">{{comments}}</td></tr><tr><td style="font-weight: 600;"><a style="padding: 6px;background: #34a7ce;color: #fff!important;border-radius: 6px;margin-left: 9px;" href="https://app.saljare.se/" target="_blank" type="button">See lead</a></td></tr></table>';
        values['body'] = content;
        values['subject'] = `New Lead`;
        let params = {
            ...values,
            type: "html",
            data: {
                companyname: customerData.industry || '-',
                businessname: selectedItem.business_name || '-',
                contactname: selectedItem.contactname || '-',
                leadscore: selectedItem.leadscore || '-',
                leadstatus: selectedItem.leadstatus || '-',
                contactmail: selectedItem.contactmail || '-',
                contactphone: selectedItem.contactphone || '-',
                leadinfo: selectedItem.leadinfo || '-',
                comments: leadChatComments || '-'
            }
        };
        // console.log('params', params);
        postMail(params)
            .then(({ data: { data } }) => {
                //alert(JSON.stringify(data));
                // setTags([]);
                //alert('Mail sent successfully');
                updateLeads(selectedItem.id, { ismobilesent: 'SENT', sales_reps: selectedReps.toString(), salesreps: (selected_Reps) })
                    .then(({ data: { data } }) => {
                        // getLeadsList(currentUser)
                    })
                setMailStatus('SUCCESS');
                setEmailModal(false);
                setVisible(false);
                setLoading(false);
                let temp = temptags
                setTags(temp)
            })
            .catch((err) => {
                let temp = temptags
                setTags(temp);
                setLoading(false)
                throw err;
            });

    };


    const getSalesrepData = () => {
        setStatus('')
        let filter = `?filter[customer_id][eq]=${currentUser.id}&fields=id,name,email`;

        getLists('CUSTOME_SALES_REP', filter)
            .then(({ data: { data } }) => {
                if (data && data.length > 0) {
                    let reps = [];
                    data.map((item, index) => {
                        reps.push({ id: item.id, name: item.email });
                    });
                    setSalesReps(data)
                    //console.log('sales data',reps);
                    setSuggestion(reps);
                    setTags(reps);
                    setTempTags(reps)
                } else {
                    setSuggestion([]);
                }
            })
            .catch((err) => {
                setSuggestion([]);
                throw err;
                // setIsloading(false)
                //   setStatus('The login detail is incorrect')
            });
    };

    const submitAnswer = () => {
        // const values = [];
        let emails = [];

        if (tags) {
            tags.map((item, index) => {
                emails.push(item.name);
            });
        }
        //mailto:comment@saljare.se
        // values['to'] = ['svar@saljare.se', ...emails];
        // value={values.notice_before_eol ? months.find(val => val.value === values.notice_before_eol) : null}
        let select_answer = answersList.find((row) => row.id === parseInt(selectAnswer));
        let selected_Reps = []
        salesReps.map((e1, i) => {
            if (tags.filter(e2 => e2.name === e1.email).length > 0) {
                selected_Reps.push(e1)
            }
        })
        // values['cc'] = ['anandsdn@gmail.com'];
        // let content = '<table style="font-size: 14px;width:100%"><tr><td style="width: 80%">{{selectAnswer}}</td></tr></table>';
        // values['body'] = content;
        // values['subject'] = `Answers`;

        // let params = {
        //     ...values,
        //     type: "html",
        //     data: {
        //         selectAnswer: select_answer.answer || '-'
        //     }
        // };

        // postMail(params)

        let obj = {
            lead: modalInfo.id,
            salesreps: (selected_Reps),
            answer: select_answer.id,
            current_status: 'not_done',
            collection: 'answer'
        }
        addApi('manage_calls_phone_meeting', obj)
            .then(({ data: { data } }) => {
                // updateLeads(selectedItem.id, { sales_reps: selectedReps.toString() });
                setMailStatus('SUCCESS');
                setAnswerModal(false);
            })
            .catch((err) => {

                throw err;
            })

        // addManageList(obj)
        //     .then(({ data: { data } }) => {
        //         //alert(JSON.stringify(data));
        //         // setTags([]);
        //         //alert('Mail sent successfully');
        //         // updateLeads(selectedItem.id, { sales_reps: selectedReps.toString() });
        //         setMailStatus('SUCCESS');
        //         setAnswerModal(false);
        //     })
        //     .catch((err) => {
        //         throw err;
        //     });
    }

    // const sendComment = () => {
    //     let emailValue = ["comment@saljare.se"];

    //     const values = [];
    //     //mailto:comment@saljare.se
    //     values['to'] = emailValue;
    //     let content = '<table style="<style="font-size:14px;width:100%;background-color: #dfe9ee7a !important;border-radius: 10px !important;margin-bottom: 0px;padding: 15px;""><tr style="font-weight: 700">Lead Details Below:</tr><tr></tr><tr><td style="font-weight: 600;">Business Name:</td></tr><tr><td>{{businessname}}</td></tr><tr><td style="font-weight: 600;">Contact Name:</td></tr><tr><td>{{contactname}}</td></tr><tr><td style="font-weight: 600;">Contact Mail:</td></tr><tr><td>{{contactmail}}</td></tr><tr><td style="font-weight: 600;">Comments:</td></tr><tr><td style="width: 80%;font-weight:bold;">{{comments}}</td></tr><tr><td style="font-weight: 600;"><a style="padding: 6px;background: #34a7ce;color: #fff!important;border-radius: 6px;margin-left: 9px;"" href="https://app.saljare.se/" target="_blank" type="button" className="btn btn-default btnBlue">See lead</a></td></tr></table>';
    //     values['body'] = content;
    //     values['subject'] = `Comments`;

    //     let params = {
    //         ...values,
    //         type: "html",
    //         data: {
    //             companyname: customerData.industry || '-',
    //             businessname: selectedItem.business_name || '-',
    //             contactname: selectedItem.contactname || '-',
    //             leadscore: selectedItem.leadscore || '-',
    //             leadstatus: selectedItem.leadstatus || '-',
    //             contactmail: selectedItem.contactmail || '-',
    //             contactphone: selectedItem.contactphone || '-',
    //             leadinfo: selectedItem.leadinfo || '-',
    //             comments: leadComments || '-'
    //         }
    //     };

    //     // console.log('params', params);
    //     postMail(params)
    //         .then(({ data: { data } }) => {
    //             //alert(JSON.stringify(data));
    //             setleadComments('');
    //             //alert('Mail sent successfully');
    //             setMailStatus('SUCCESS');
    //             setCommentModal(false);
    //             setVisible(false)
    //         })
    //         .catch((err) => {
    //             throw err;
    //         });

    // };

    return (
        <>
            <div className="card-body py-3" style={{ backgroundColor: '#f5f8fa' }}>
                <div className="tab-content">
                    {/* begin::Tap pane */}
                    <div className="tab-pane fade show active" id="kt_table_widget_5_tab_1">
                        {/* begin::Table container */}
                        <div className="table-responsive">

                            {/* begin::Table */}
                            {
                                leads?.length > 0 ?
                                    <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4 lead-table">
                                        {/* begin::Table head */}
                                        <thead>
                                            <tr className="border-0">
                                                <th className="p-0 min-w-10px" />
                                                <th className="p-0 min-w-10px" />
                                                {/* <th className="p-0 min-w-200px" />
                                                <th className="p-0 min-w-200px" /> */}
                                                {/* <th className="p-0 min-w-200px" /> */}
                                                <th className="p-0 min-w-10px" />
                                                <th className="p-0 min-w-10px" />

                                            </tr>
                                        </thead>
                                        {/* end::Table head */}
                                        {/* begin::Table body */}
                                        <tbody>
                                            {leads.map((item, index) => (
                                                <tr style={{
                                                    borderBottom: '1px solid #e3e3e3'
                                                    // backgroundColor: isDashboard ? (item.leadstatus == "WON" ? '#97DBAE' :
                                                    //     item.leadstatus == "LOST" ? '#F4BBBB' : 'transparent') : 'transparent'
                                                }}>

                                                    <td>
                                                        <div className="type-1">
                                                            {isDashboard &&
                                                                <div className="symbol symbol-45px me-2">

                                                                    {item.status_type ? (
                                                                        <div
                                                                            className="card-icon-rounded"
                                                                            style={{
                                                                                backgroundColor: item.status_type
                                                                                    ? statuscolors[item.status_type]
                                                                                    : ''
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    item.status_type ? (
                                                                                        statusimages[item.status_type]
                                                                                    ) : (
                                                                                        ''
                                                                                    )
                                                                                }
                                                                                className="invert-img"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        '-'
                                                                    )}
                                                                </div>
                                                            }
                                                            <div>
                                                            <p className="text-dark fw-bolder mb-1 fs-6">
                                                                {item.business_name}
                                                            </p>
                                                            <p className="text-dark fw-bolder mb-1 fs-6 contactname">{item.contactname}</p>

                                                            </div>

                                                            {/* <span className="text-muted fw-bold d-block">{localLanguage.business_name}</span> */}
                                                          
                                                        </div>

                                                    </td>
                                                    <td>
                                                        {/* <span className="text-muted fw-bold d-block">{item.sales_reps ? localLanguage.sales_rep : null}</span> */}
                                                        <p className="text-dark fw-bolder mb-1 fs-6">
                                                            {localLanguage[item.status_type.toLowerCase()]}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        {/* <span className="text-muted fw-bold d-block">{localLanguage.contact_name}</span> */}
                                                        <p className="text-dark fw-bolder mb-1 fs-6">{item.leaddate ? moment(item.leaddate).format('YYYY-MM-DD') : ''}</p>
                                                    </td>
                                                    {/* { isDashboard &&
                                                        <td>
                                                            <span className="text-muted fw-bold d-block">{localLanguage.feedback}</span>
                                                            <p className="text-dark fw-bolder mb-1 fs-6">{getFeedBackObj(item.id).map((obj, i) => (
                                                                <div>{getFeedBackvalue(obj.feedback)} {obj.feedback ? <span className="qus" onClick={() => {
                                                                    setSelectedFeedbackItem(obj.feedback_comment);
                                                                    setFeedbackModal(true);
                                                                }}>?</span> : ''}</div>
                                                            ))} </p>
                                                        </td>
                                                    } */}
                                                    {/* <td>
                                                        <span className="text-muted fw-bold d-block">
                                                            {localLanguage.estimated_score}
                                                        </span>
                                                        <p className="text-dark fw-bolder mb-1 fs-6">{item.leadscore}</p>
                                                    </td> */}

                                                    <td>
                                                        <button type="button" onClick={() => { getSalesrepData(); setRating(''); setSelectedItem(item); setModalInfo(item); setVisible(true); setMeetingTime(''); openModal(item); }} className="btn btn-primary btnBlue">{localLanguage.see_lead_btn}</button>
                                                    </td>

                                                    {/* 
                                                    { isDashboard &&
                                                        <>
                                                            <td>
                                                                <span className="text-muted fw-bold d-block">{localLanguage.lead_status}</span>
                                                                <p className="text-dark fw-bolder mb-1 fs-6">{item.status_type}</p>
                                                            </td>
                                                            <td>
                                                                <span className="text-muted fw-bold d-block">{localLanguage.email}</span>
                                                                <div onClick={() => { setRating(''); setSelectedItem(item); setVisible(false); openModal(item); setEmailModal(true); }}>
                                                                    <img
                                                                        style={{ cursor: 'pointer', width: item.ismobilesent == 'SENT' ? 31 : 26, marginLeft: 5 }}
                                                                        src={item.ismobilesent == 'SENT' ? Email : EmailNotSent}
                                                                        className="h-50 align-self-center"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <span className="text-muted fw-bold d-block">{localLanguage.feedback}</span>
                                                                <div onClick={() => { setRating(''); setSelectedItem(item); setVisible(false); setLoading(false); openModal(item); setFeedbackModalMail(true); }}>
                                                                    <img
                                                                        style={{ cursor: 'pointer', width: 31, marginLeft: 5 }}
                                                                        src={FeedbackImg}
                                                                        className="h-50 align-self-center"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </td>

                                                            <td></td>
                                                        </>
                                                    } */}
                                                </tr>

                                            ))
                                            }
                                        </tbody>
                                        {/* end::Table body */}
                                    </table> : <span>{localLanguage.no_records}</span>
                            }
                        </div>
                        {/* end::Table */}
                    </div>
                    {/* end::Tap pane */}
                </div>
            </div>
            {/* start::Feedback modal */}

            <Modal
                className="modal fade"
                id="kt_modal_select_location"
                data-backdrop="static"
                tabIndex={-1}
                role="dialog"
                show={feedbackModalMail}
                dialogClassName="modal-lg"
                aria-hidden="true"
            >

                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}> {localLanguage.feedback} </h3>
                        <div className="btn btn-icon btn-sm btn-light-primary" onClick={() => {
                            setFeedbackModalMail(false);
                        }}>
                            <KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
                        </div>
                    </div>
                    <div className="modal-body">
                        <ReactTags
                            ref={reactTags}
                            tags={tags}
                            suggestions={suggestions}
                            onDelete={onDelete}
                            onAddition={onAddition}
                            placeholder="Ex: abc@gmail.com, xyz@gmail.com"
                        />
                        {feedbackStatus == "NO_REP" ? (
                            <div className='mt-5 mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{localLanguage.valid_sales_rep}</div>
                            </div>
                        ) : null}
                        {feedbackStatus == "SUCCESS" ? (
                            <div className='mt-5 mb-lg-15 alert alert-success'>
                                <div className='alert-text font-weight-bold'>{localLanguage.mail_sent}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="modal-footer">

                        <a disabled={loading} onClick={() => {
                            sendFeedback()
                        }} className="btn btn-primary">{loading && (
                            <CircularProgress className={"progressbar"} />
                        )}{localLanguage.send}</a>

                    </div>
                </div>

            </Modal>
            {/* start::Feedback modal */}

            <Modal
                className="modal fade"
                id="kt_modal_select_location"
                data-backdrop="static"
                tabIndex={-1}
                role="dialog"
                show={feedbackModal}
                dialogClassName="modal-lg"
                aria-hidden="true"
            >

                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}> {localLanguage.feedback} </h3>
                        <div className="btn btn-icon btn-sm btn-light-primary" onClick={() => {
                            setFeedbackModal(false);
                            setSelectedFeedbackItem('')
                        }}>
                            <KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
                        </div>
                    </div>
                    <div className="modal-body">
                        {selectedFeedbackItem}
                    </div>
                    <div className="modal-footer">
                        <a onClick={() => {
                            sendFeedback()
                        }} className="btn btn-primary">{localLanguage.cancel_btn}</a>

                    </div>
                </div>

            </Modal>
            {/* begin::Modal */}
            <Modal
                className="modal fade"
                id="kt_modal_select_location"
                data-backdrop="static"
                tabIndex={-1}
                role="dialog"
                show={visible}
                dialogClassName="modal-lg"
                aria-hidden="true"
            // onHide={dissmissLocation}
            >
                <div className="modal-content">
                    <div className="modal-header list-header" style={{ backgroundColor: modalInfo && (modalInfo.leadstatus == "" || modalInfo.leadstatus == null) ? 'transparent' : modalInfo.leadstatus == "WON" ? '#50cd89' : '#FF8080' }}>
                        <h3 className="modal-title" style={{ textAlign: 'center', width: '100%', marginLeft: 54 }}> {modalInfo && modalInfo.business_name}</h3>
                        <div className="btn btn-icon btn-sm btn-light-primary" onClick={() => { answerModal ? setAnswerModal(false) : setStatus(false); setVisible(false) }}>
                            <KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
                        </div>
                    </div>
                    <div className="modal-body">
                        {status == "FAIL" ? (
                            <div className='mt-5 mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{localLanguage.nuture_notsend}</div>
                            </div>
                        ) : null}
                        {status == "SUCCESS" ? (
                            <div className='mt-5 mb-lg-15 alert alert-success'>
                                <div className='alert-text font-weight-bold'>{localLanguage.nuture_send}</div>
                            </div>
                        ) : null}
                        {status == "ALREADY" ? (
                            <div className='mt-5 mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{localLanguage.nuture_send_already}</div>
                            </div>
                        ) : null}

                        {
                            !answerModal &&
                            <div className='row g-5'>
                                <div className="col-xl-6">
                                    {/* <span>Status: </span> */}
                                   Status: <TextField
                                        style={{ width: '100%' }}
                                        variant={"filled"}
                                        required
                                        id="leadstaus"
                                        select
                                        defaultValue={'interested'}
                                        value={leadStatusNew}
                                        //error={errValues && errValues.type_of_leasingError ? true : false}
                                        name="type_of_leasing"
                                        onChange={(e) => {
                                            console.log(modalInfo, "eeee", e.target.value)
                                            let obj = {
                                                id: modalInfo.id,
                                                leadstatusnew: e.target.value
                                            }
                                            setLeadStatusNew(e.target.value)
                                            updateLeadData(obj)
                                        }}
                                        // SelectProps={{
                                        //     MenuProps: {
                                        //         className: classes.menu,
                                        //     },
                                        // }}
                                        label=""
                                        margin="normal"
                                    >
                                        <MenuItem key={'intresserad'} value="interested">Intresserad</MenuItem>
                                        <MenuItem key={'Offererad'} value="Offered">Offert skickad</MenuItem>
                                        <MenuItem key={'såld'} value="sold">Såld</MenuItem>
                                        <MenuItem key={'ej intresserad'} value="not_interested">Ej intresserad</MenuItem>
                                        <MenuItem key={'ej kvali serad'} value="not_qualified">Ej kvalificerad</MenuItem>
                                    </TextField>
                                    {/* {modalInfo && modalInfo.leadstatus == "WON" && <a style={{ height: 40 }} className="btn btn-success color-white ms-2 btnGreen btn-hover-scale" disabled="true">{localLanguage.approved}</a>}
                                    {modalInfo && modalInfo.leadstatus == "LOST" && <a style={{ height: 40 }} className="btn btn-danger ms-2" disabled="true">{localLanguage.denied}</a>}
                                    {modalInfo && (modalInfo.leadstatus == "" || modalInfo.leadstatus == null) && <div>
                                        <a onClick={() => setLeadStatus('WON')} style={{ height: 40 }} className="btn btn-success color-white ms-2 btnGreen btn-hover-scale" disabled="true">{localLanguage.accept_lead}</a>
                                        <a onClick={() => setLeadStatus('LOST')} style={{ height: 40, color: '#ffffff !important' }} className="btn btn-danger ms-2">{localLanguage.deny_lead}</a>
                                    </div>} */}
                                </div>
                                <div className="col-xl-6">
                                    {modalInfo && modalInfo?.status_type == "MEETING" &&
                                        <div className="rowMedium">
                                            <div className="card-icon-rounded" style={{ backgroundColor: modalInfo && modalInfo.status_type ? statuscolors[modalInfo.status_type] : '#50cd89', marginRight: 20 }}>
                                                <img src={Meeting} alt="" className="invert-img" />
                                            </div>
                                            <h5>{localLanguage.want_meeting}</h5>
                                        </div>
                                    }

                                    {modalInfo && modalInfo?.status_type == "PHONE" &&
                                        <div className="rowMedium">
                                            <div className="card-icon-rounded" style={{ backgroundColor: modalInfo && modalInfo.status_type ? statuscolors[modalInfo.status_type] : '#50cd89', marginRight: 20 }}>
                                                <img src={Phone} alt="" className="invert-img" />
                                            </div>
                                            <h5>{localLanguage.want_called}</h5>
                                        </div>
                                    }
                                    {modalInfo && modalInfo?.status_type == "INFO" &&
                                        <div className="rowMedium">
                                            <div className="card-icon-rounded" style={{ backgroundColor: modalInfo && modalInfo.status_type ? statuscolors[modalInfo.status_type] : '#50cd89', marginRight: 20 }}>
                                                <img src={Info} alt="" className="invert-img" />
                                            </div>
                                            <h5>{localLanguage.want_more_info}</h5>
                                        </div>
                                    }
                                </div>
                            </div>

                        }
                        <div className="row g-5 g-xl-8">
                            {
                                !answerModal &&
                                <div className="col-xl-6 card-grey mb-5" style={{ backgroundColor: '#b7cfe0 !important' }}>
                                    <div className='flex-row mb-8 mt-5'>
                                        <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                            <i className='bi bi-building fs-3' style={{ color: '#fff' }}></i>
                                        </div>

                                        <div className='ms-5' style={{ marginTop: -5 }}>
                                            <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.business_name}</div>
                                            <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.business_name}</a>
                                        </div>
                                    </div>

                                    <div className='flex-row mb-8'>
                                        <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                            <i className='bi bi-activity fs-3' style={{ color: '#fff' }}></i>
                                        </div>

                                        <div className='ms-5' style={{ marginTop: -5 }}>
                                            <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.lead_score}</div>
                                            <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.leadscore}</a>
                                        </div>

                                    </div>

                                    <div className='flex-row mb-8'>
                                        <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                            <i className='bi bi-person fs-3' style={{ color: '#fff' }}></i>
                                        </div>
                                        <div className='ms-5' style={{ marginTop: -5 }}>
                                            <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.contact_name}</div>
                                            <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.contactname}</a>
                                        </div>
                                    </div>

                                    <div className='flex-row mb-8'>
                                        <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                            <i className='bi bi-envelope fs-3' style={{ color: '#fff' }}></i>
                                        </div>
                                        <div className='ms-5' style={{ marginTop: -5 }}>
                                            <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.contact_email}</div>
                                            <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.contactmail}</a>
                                        </div>
                                    </div>

                                    <div className='flex-row mb-8'>
                                        <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                            <i className='bi bi-back fs-3' style={{ color: '#fff' }}></i>
                                        </div>

                                        <div className='ms-5' style={{ marginTop: -5 }}>
                                            <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.lead_status}</div>
                                            <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.leadstatus ? "WON" : "NA"}</a>
                                        </div>
                                    </div>

                                    <div className='flex-row mb-8'>
                                        <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                            <i className='bi bi-calendar-date fs-3' style={{ color: '#fff' }}></i>
                                        </div>

                                        <div className='ms-5' style={{ marginTop: -5 }}>
                                            <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.created_on}</div>
                                            <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.leaddate}</a>
                                        </div>
                                    </div>
                                    <div className='flex-row mb-8'>
                                        <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                            <i className='bi bi-phone fs-3' style={{ color: '#fff' }}></i>
                                        </div>

                                        <div className='ms-5' style={{ marginTop: -5 }}>
                                            <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.created_phone}</div>
                                            <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.contactphone}</a>
                                        </div>
                                    </div>
                                    {/* <div className='flex-row mb-8'>
                                        <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                            <i className='bi bi-phone fs-3' style={{ color: '#fff' }}></i>
                                        </div>

                                        <div className='ms-5' style={{ marginTop: -5 }}>
                                            <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.position}</div>
                                            <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.customer_id?.title ? modalInfo.customer_id?.title : '-'}</a>
                                        </div>
                                    </div> */}
                                    {/* <div className='flex-row mb-8'>
										<div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
											<i className='bi bi-phone fs-3' style={{ color: '#fff' }}></i>
										</div>

										<div className='ms-5' style={{ marginTop: -5 }}>
											<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.position}</div>
											<a className='fs-5 fw-bold text-gray-900'>{'CEO'}</a>
										</div>
									</div> */}
                                    {leadchat && leadchat.length == 0 && modalInfo.leadcomment != null &&
                                        <div className='flex-row mb-8'>
                                            <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                                <i className='bi bi-chat fs-3' style={{ color: '#fff' }}></i>
                                            </div>

                                            <div className='ms-5' style={{ marginTop: -5 }}>
                                                <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.lead_conversation}</div>
                                                <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.leadcomment}</a>
                                            </div>
                                        </div>
                                    }

                                </div>
                            }
                            <div className={`${answerModal ? 'col-xl-12' : 'col-xl-6'} mb-5`}>

                                <div className='fs-5 fw-bolder text-gray-600 mb-5'>{localLanguage.history}</div>
                                {
                                    leadchat.map((item, index) => {
                                        return (
                                            index % 2 ?
                                                <div className={'flex-row mb-8'} key={index} style={{ justifyContent: 'flex-end' }}>
                                                    <div className='ms-5 chatbox'>
                                                        <a className='fs-6 fw-bold text-gray-900'>{item.comment}</a>
                                                    </div>
                                                    <div className='card-icon-rounded-small' style={{ padding: 5 }}>
                                                        <img
                                                            alt='Logo'
                                                            className='h-20px logo'
                                                            src={"../media/logos/userprofile.jpeg"}
                                                        />
                                                    </div>


                                                </div>
                                                :
                                                <div className={'flex-row mb-8'} key={index} >

                                                    {/* <div className='card-icon-rounded-small' style={{ backgroundColor: '#17D7A0', padding: 5 }}>
														<i className={ 'bi bi-person-fill fs-3'} style={{ color: '#fff' }}></i>
													*/}
                                                    <div className='card-icon-rounded-small' style={{ padding: 5 }}>
                                                        <img
                                                            alt='Logo'
                                                            className='h-20px logo'
                                                            src={"../media/logos/userprofile.jpeg"}
                                                        />
                                                    </div>

                                                    <div className='ms-5 chatbox'>
                                                        <a className='fs-6 fw-bold text-gray-900 comnt'>{item.comment}</a>
                                                    </div>
                                                </div>
                                        )
                                    })
                                }
                                {
                                    answerModal &&
                                    <>
                                        <div className='row mb-10'>
                                            <div className='col-lg-12 col-xl-12'>
                                                <select
                                                    className='form-select form-select-solid'
                                                    name='layout-builder[layout][header][width]'
                                                    onChange={(e) =>
                                                        setSelectAnswer(e.target.value)
                                                    }
                                                >
                                                    <option value={''}>{'Choose answer '}</option>
                                                    {
                                                        answersList.length > 0
                                                        && answersList.map((item, i) => {
                                                            return (
                                                                <option key={i} value={item.id}>{item.answer}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row mb-10'>
                                            <div className='col-lg-12 col-xl-12'>
                                                <ReactTags
                                                    ref={reactTags}
                                                    tags={tags}
                                                    suggestions={suggestions}
                                                    onDelete={onDelete}
                                                    onAddition={onAddition}
                                                    placeholder=""
                                                />
                                            </div>
                                        </div>
                                    </>}
                            </div>
                            <div className={`${answerModal ? 'btm-btn j-center' : 'btm-btn btn-left'} mb-5 `}>
                                {
                                    answerModal ? <>
                                        <div className='ms1-5'>
                                            <a onClick={() => { setAnswerModal(false) }} className="btn btn-success color-white ms-2 btnDarkBlue round" disabled="true">{localLanguage.cancel_btn}</a>
                                        </div><div className='ms1-5'>
                                            <a onClick={() => { submitAnswer() }} className="btn btn-success color-white ms-2 btnDarkBlue round" disabled="true">{localLanguage.send}</a>
                                        </div></>
                                        :
                                        <>

                                            <div className='ms1-5'>
                                                <a onClick={() => { sendNuture(modalInfo.id); }} className="btn color-white ms-2 btnDarkBlue btnPink" disabled="true">{loading && (
                                                    <CircularProgress className={"progressbar"} />
                                                )}{localLanguage.nuture_list}</a>
                                            </div>
                                            <div className="btn1">
                                                {
                                                    <div className='ms1-5'>
                                                        <a onClick={() => { setisBookaMeeting(false); setVisible(false); getSalesrepData(); setshowMeetingModal(true); }} className="btn color-white ms-2 btnDarkBlue" disabled="true">
                                                            {loading && (
                                                                <CircularProgress className={"progressbar"} />
                                                            )}{localLanguage.automate}<span class="badge badge-warning">Beta</span></a>
                                                    </div>
                                                }
                                                {
                                                    <div className='ms1-5'>
                                                        <a className="btn color-white ms-2 btnBlack" disabled="true"
                                                            onClick={() => { setisBookaMeeting(true); setVisible(false); setshowMeetingModal(true); }}
                                                        >
                                                            {loading && (
                                                                <CircularProgress className={"progressbar"} />
                                                            )}{localLanguage.book_meeting1}</a>
                                                    </div>
                                                }
                                            </div>

                                            {/* <div className='ms-5'>
                                                <a onClick={() => { setRating(modalInfo.lead_rating ? modalInfo.lead_rating : 0); setRatingModal(true); }} className="btn btn-success color-white ms-2 btnDarkBlue" disabled="true">{modalInfo.lead_rating ? modalInfo.lead_rating : 0}</a>
                                            </div> */}
                                            {/* <div className='ms-5'>
                                                <a onClick={() => { setVisible(false); setCommentModal(true); }} className="btn btn-success color-white ms-2 btnBlue" disabled="true">{localLanguage.comment}</a>
                                            </div> */}
                                            <div className="btn1">
                                                <div className='ms1-5'>
                                                    <a onClick={() => { getSalesrepData(); setAnswerModal(true); }} className="btn color-white ms-2 btnBlue" disabled="true">{loading && (
                                                        <CircularProgress className={"progressbar"} />
                                                    )}{localLanguage.answer}</a>
                                                </div>
                                                <div className='ms1-5'>
                                                    <a onClick={() => { setVisible(false); setLoading(false); setEmailModal(true); }} className="btn color-white ms-2 btnBlue" disabled="true">{loading && (
                                                        <CircularProgress className={"progressbar"} />
                                                    )}{localLanguage.send_lead}</a>
                                                </div>
                                            </div>

                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* lead info */}
            {/* start::Email modal */}

            <Modal
                className="modal fade"
                id="kt_modal_select_location"
                data-backdrop="static"
                tabIndex={-1}
                role="dialog"
                show={emailModal}
                dialogClassName="modal-lg"
                aria-hidden="true"
            >

                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}> Mail to</h3>
                        <div className="btn btn-icon btn-sm btn-light-primary" onClick={() => setEmailModal(false)}>
                            <KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
                        </div>
                    </div>
                    <div className="modal-body">
                        <ReactTags
                            ref={reactTags}
                            tags={tags}
                            suggestions={suggestions}
                            onDelete={onDelete}
                            onAddition={onAddition}
                            placeholder="Ex: abc@gmail.com, xyz@gmail.com"
                        />
                        {mailstatus == "NO_REP" ? (
                            <div className='mt-5 mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{localLanguage.valid_sales_rep}</div>
                            </div>
                        ) : null}
                        {mailstatus == "SUCCESS" ? (
                            <div className='mt-5 mb-lg-15 alert alert-success'>
                                <div className='alert-text font-weight-bold'>{localLanguage.mail_sent}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="modal-footer">
                        <a disabled={loading} onClick={() => sendEmail()} className="btn btn-primary">
                            {loading && (
                                <CircularProgress className={"progressbar"} />
                            )}{localLanguage.send}</a>

                    </div>
                </div>

            </Modal>

            {/* start::Meeting invite modal */}

            <Modal
                className="modal fade"
                id="kt_modal_select_location"
                data-backdrop="static"
                tabIndex={-1}
                role="dialog"
                show={showMeetingModal}
                dialogClassName="modal-lg"
                aria-hidden="true"
            >

                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}> {localLanguage.meeting} - {modalInfo?.business_name}</h3>
                        <div className="btn btn-icon btn-sm btn-light-primary" onClick={() => { setisBookaMeeting(false); setshowMeetingModal(false) }}>
                            <KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
                        </div>
                    </div>
                    <div className="modal-body">
                        {bookmeetingStatus == "FAIL" ? (
                            <div className='mt-5 mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{localLanguage.bookmeeting_notsend}</div>
                            </div>
                        ) : null}
                        {bookmeetingStatus == "SUCCESS" ? (
                            <div className='mt-5 mb-lg-15 alert alert-success'>
                                <div className='alert-text font-weight-bold'>{localLanguage.bookmeeting_send}</div>
                            </div>
                        ) : null}
                        <div>
                            {/* <div className="flex-row" style={{ justifyContent: 'space-between' }}> */}

                            {
                                !isBookaMeeting && <>
                                    <h5 className="ml-5 mr-10">{localLanguage.title}</h5>
                                    <TextField
                                        variant={"standard"}
                                        id="our_agreement_ref"
                                        // label="Meeting title"
                                        name={'title'}
                                        className="meetingtitle"
                                        value={meetingtittle}
                                        onChange={(e) => {
                                            //alert(e.target.value);
                                            setmeetingtittle(e.target.value);
                                        }}
                                        margin="normal"
                                        width="100%"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    /></>
                            }
                            <div className={`${!isBookaMeeting ? 'datetime' : ''}`}>
                                <div>
                                    {localLanguage.meeting_time} :<br />
                                    <DatePicker
                                        showTimeSelect
                                        // minTime={new Date(0, 0, 0, 10, 0)}
                                        // maxTime={new Date(0, 0, 0, 22, 0)}
                                        minDate={new Date()} //set the current date to disable past date
                                        onChange={date => setMeetingTime(date)}
                                        selected={meetingTime}
                                        dateFormat="yyyy-MM-dd h:mmaa"
                                    />
                                </div>
                                {
                                    !isBookaMeeting &&
                                    <div>
                                        {localLanguage.meeting_end_time} :<br />
                                        <DatePicker
                                            showTimeSelect
                                            minDate={new Date()}
                                            // minTime={new Date(0, 0, 0, 10, 0)}
                                            // maxTime={new Date(0, 0, 0, 22, 0)}
                                            onChange={date => setMeetingEndTime(date)}
                                            selected={meetingEndTime}
                                            dateFormat="yyyy-MM-dd h:mmaa"
                                        />
                                    </div>
                                }
                            </div>
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateTimePicker']}>
                                    <DemoItem label={localLanguage.meeting_time}>
                                        <DateTimePicker label=""
                                            value={meetingTime || null}
                                            // onChange={(newValue) => setMeetingTime(newValue?.$d)}
                                        />
                                    </DemoItem>
                                    <DemoItem label={localLanguage.meeting_end_time}>
                                        <DateTimePicker label=""
                                            value={meetingEndTime || null}
                                            // onChange={(newValue) => setMeetingEndTime(newValue?.$d)}
                                        />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider> */}
                        </div>
                        <br />
                        <ReactTags
                            ref={reactTags}
                            tags={tags}
                            suggestions={suggestions}
                            onDelete={onDelete}
                            onAddition={onAddition}
                            placeholder="Ex: abc@gmail.com, xyz@gmail.com"
                        />
                        {mailstatus == "NO_REP" ? (
                            <div className='mt-5 mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{localLanguage.valid_sales_rep}</div>
                            </div>
                        ) : null}
                        {mailstatus == "SUCCESS" ? (
                            <div className='mt-5 mb-lg-15 alert alert-success'>
                                <div className='alert-text font-weight-bold'>{localLanguage.mail_sent}</div>
                            </div>
                        ) : null}
                        {meetingstatus == "SUCCESS" ? (
                            <div className='mt-5 mb-lg-15 alert alert-success'>
                                <div className='alert-text font-weight-bold'>{localLanguage.meeting_invite}</div>
                            </div>
                        ) : null}
                        {meetingstatus == "FAIL" ? (
                            <div className='mt-5 mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{localLanguage.meeting_invite_fail}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" onClick={() => { setRating(''); setSelectedItem(item); setModalInfo(item); setVisible(true); setMeetingTime(''); openModal(item); }} className="btn btn-primary btnBlue">Se leadet</button> */}


                        <a disabled={loading} onClick={() => { setshowMeetingModal(false); setVisible(true) }} className="btn btn-primary btnBlue">
                            {loading && (
                                <CircularProgress className={"progressbar"} />
                            )}{localLanguage.see_lead_btn}</a>
                        <a disabled={loading} onClick={() => sendMeeting(modalInfo)} className="btn btn-primary btnDarkBlue">
                            {loading && (
                                <CircularProgress className={"progressbar"} />
                            )}{isBookaMeeting ? localLanguage.book_meeting : localLanguage.invite}</a>

                    </div>
                </div>

            </Modal>
        </>

    )
}
