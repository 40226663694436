module.exports = {
    // API_URL: 'http://localhost:8055',
    // API_URL: 'https://api.saljare.se/salescrm',
    API_URL: 'https://api.dr.se/salescrm',
    WEB_URL: 'https://portal.dr.se',
    WEB_URLDEMO: 'https://salescrm.inspirepro.co.in/',
    DATAURLS: {
        AUTOLOGIN: { url: `/auth/autologin` },
        LANGUAGES: { url: `/items/languages` },
        USERS: { url: `/users` },
        COMPANY_CURRENT_STATUS: { url: `/items/company_current_status` },
        LEADS: { url: `/items/leads` },
        CUSTOMER_REPORTS: { url: `/items/customer_reports` },
        CUSTOME_SALES_REP: { url: `/items/customer_sales_rep` },
        SEND_MAIL: { url: `/mail` },
        LEAD_CHATS : { url: `/items/lead_chats` },
        LANGUAGE : { url: `/items/languages` },
        SETTINGS : { url: `/items/settings` },
        SALESTRAINING : { url: `/items/salestraining` },
        ANSWERS : { url: `/items/answers` },
        CRM : { url: `/items/crm` },
        FEEDBACK : { url: `/items/feedback` },
        NUTURE : { url: `/items/nuture_list` },
        EVENTS_LIST : { url: `/items/event_lists` },
        SMS : { url: `/items/sms` },
    },
    FEEDBACK_STATUS: [
		{
			id: 1,
			value: 'Very Dissatisfied'
		}, {
			id: 2,
			value: 'Dissatisfied'
		}, {
			id: 3,
			value: 'Neutral'
		}, {
			id: 4,
			value: 'Satisfied'
		}, {
			id: 5,
			value: 'Very Satisfied'
		}]
}
