/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { updateUser, getLists } from '../dashboard/redux/DashboardCrud'
import { useSelector } from "react-redux";
import { useLang } from '../../../_metronic/i18n/Metronici18n'

export default function Page() {
	const { data } = useSelector(({ auth }) => auth.user);
	const [report, setReport] = useState('');
	const { swedishLang, englishLang } = useSelector(({ auth }) => auth);
	const selectedLang = useLang()
	const [localLanguage, setLocalLanguage] = useState({})

	useEffect(() => {
		if (selectedLang === 'english') {
			setLocalLanguage(englishLang)
		} else {
			setLocalLanguage(swedishLang)
		}
	}, [selectedLang, swedishLang, englishLang]);

	useEffect(() => {

		if (data && data.id) {
			getSettings();
		}

	}, [data])

	const getSettings = () => {
		let filter = `/1?fields=*.*.*`;

		getLists('SETTINGS', filter)
			.then(({ data: { data } }) => {
				//console.log('customer reports', data);
				if (data) {
					setReport(data);

					//window.location.reload();
				} else {
					setReport('');
				}
			})
			.catch((err) => {
				throw err;
			});
	};

	// console.log("report", report)

	return (
		<React.Fragment>
			{/* <h2 className="mb-10">{localLanguage.meeting_guide}</h2> */}

			<div className="row">
				{/* <div className="meeting-container">
					<h2 className="MEETING-TITLE">{localLanguage.meeting_title}</h2>
				</div> */}
				<div className="col-xl-7" style={{ padding: '6px' }}>
					{/* <div className="card-white mb-5" dangerouslySetInnerHTML={{ __html: report?.meeting_text }} /> */}
					<div className="card-white meeting-info">
						<div className="meeting-text" dangerouslySetInnerHTML={{ __html: selectedLang === 'english' ? report.meeting_text_eng : report.meeting_text }} >
						</div>
					</div>
				</div>
				<div className="col-xl-5" style={{ padding: '6px' }}>
					<div className="card-white meeting-info">
						<div className="flex-column vid-play">
							<a target="_blank" href={report?.meeting_video_url?.data?.full_url}>
								<div>
									<img
										className='img-1'
										src={'https://api.dr.se/uploads/salescrm/originals/beee2751-91a6-4e05-9934-b1bcb4df375e.png'}
										alt='metronic'
									/>


								</div>
							</a>
						</div>
						<div className="meeting-text" dangerouslySetInnerHTML={{ __html: selectedLang === 'english' ? report.meeting_text_eng1 : report.meeting_text1 }} >
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export { Page };